/* eslint-disable @typescript-eslint/naming-convention */
/* eslint-disable prefer-arrow/prefer-arrow-functions */
/* eslint-disable arrow-parens */
/* eslint-disable @typescript-eslint/explicit-function-return-type */
import { Component, Input, OnInit } from '@angular/core';
import { ModalDataService } from 'app/modules/services/modal-data.service';
import { ModalService } from 'app/modules/services/modal.service';
import { ClipboardModule } from '@angular/cdk/clipboard';
import { Clipboard } from '@angular/cdk/clipboard';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';
import { Observable, Observer } from 'rxjs';
import { saveAs } from 'file-saver';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { copyImageToClipboard } from 'copy-image-clipboard';
import { ToastrService } from 'ngx-toastr';


/**
 * Component
 */
@Component({
  selector: 'app-qrcode-view',
  templateUrl: './qrcode-view.component.html',
  styleUrls: ['./qrcode-view.component.scss']
})

/**
 * Component
 */
export class QrcodeViewComponent implements OnInit {
  public qrSrc: string;
  public fileGrouping: any;
  isLoading = false;

  //download image
  public base64Image: any;
  public httpHeaders: HttpHeaders;
  public myAngularxQrCode: string = '';
  public qrCodeDownloadLink: SafeUrl = '';

  /**
   * constructor
   */
  constructor(
    private modalService: ModalService,
    private modalDataService: ModalDataService,
    private clipboard: Clipboard,
    private sanitizer: DomSanitizer,
    private http: HttpClient,
    private toastr: ToastrService,
  ) {

  }

  /**
   * initialy load
   */
  ngOnInit(): void {

    // this.fileGrouping = FILE_GROUPING;
    this.modalDataService.qrCodeObserve.subscribe((src: string) => {
      if (!src) { return; }
      this.qrSrc = src;
      console.log(this.qrSrc);
      this.myAngularxQrCode = this.qrSrc;
    });
  }
  /**
   * Close modal
   */
  public closeModel(): void {
    this.modalService.close('qr-code-modal');
    // if (this.secondModalOpen) {
    //   this.modalService.open('open-search-modal');
    // }
  }

  /**
   * file loaDER
   */
  viewImage(): void {
    this.isLoading = true;
    setTimeout(() => this.isLoading = false, 2000);
  }
  // public startDownload(url) {

  //   window.location.href = url;
  // }

  /**
   * download image
   *
   * @param data
   */

  public onDownloadQrImage(url: string) {
    fetch(url, { method: 'GET', headers: { accept: 'application/json' } })
      .then(response => response.blob())
      .then(blob => {
        const urlblob = window.URL.createObjectURL(blob);
        const link = document.createElement('a');
        link.href = urlblob;
        link.download = 'code spot'; // replace 'image.jpg' with the filename you want to save
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        window.URL.revokeObjectURL(urlblob);
        this.modalService.close('qr-code-modal');
      });


  }


  /**
   * Get file format
   *
   * @param type
   */
  public findFileType(data: string) {
    return data?.split('.')[data?.split('.').length - 1];
  }

  /**
   * copy image
   * @param url
   */
  onCopyQrImage(url) {
    copyImageToClipboard(
      url,
    )
      .then(() => {
        this.toastr.success('Image copied');
        this.modalService.close('qr-code-modal');
      })
      .catch((e) => {
        this.toastr.error('Something went wrong');
      });

  }
}

