import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { UserSignInService } from 'app/modules/services/use-sign-in.service';

@Component({
  selector: 'app-resend-otp-popup',
  templateUrl: './resend-otp-popup.component.html',
  styleUrls: ['./resend-otp-popup.component.scss']
})
export class ResendOtpPopupComponent implements OnInit {

  constructor(
    private _router: Router,
    private dialogRef: MatDialogRef<ResendOtpPopupComponent>,
    private userLoginService: UserSignInService,
  ) { }

  ngOnInit(): void {
    this.userLoginService.userOtpResend().subscribe((res) => {
      console.log(res);
    });
  }
  /**
   * otp verification
   */
  public redirectToOtpVerification(): void {
    this.dialogRef.close();
    this._router.navigateByUrl('/user-otp-verification');

  }

}
