/* eslint-disable @typescript-eslint/explicit-function-return-type */
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { LocalStorageService } from 'app/modules/services/local-storage.service';

import { ModalDataService } from 'app/modules/services/modal-data.service';
import { ModalService } from 'app/modules/services/modal.service';
import { SessionStorageService } from 'app/modules/services/session-storage.service';
import { ProfileService } from 'app/modules/user/student-profile/services/profile.service';

// text editor
const MAX_LENGTH = 1000;
import { ToastrService } from 'ngx-toastr';
@Component({
    selector: 'app-add-summary-popup',
    templateUrl: './add-summary-popup.component.html',
    styleUrls: ['./add-summary-popup.component.scss']
})
export class AddSummaryPopupComponent implements OnInit {
    @Output() profileUpdatedEvent = new EventEmitter<any>();
    @Input() userDetails: any;
    public imagrSrc: string;
    public fileGrouping: any;
    isLoading = false;
    public summaryForm: FormGroup | any;
    public studentDetail: any = null;
    public submitted: boolean = false;
    public processing: boolean = false;

    
  // text editor
  public quillConfig = {
    "toolbar": false
  };

    constructor(
        private modalService: ModalService,
        private modalDataService: ModalDataService,
        private fb: FormBuilder,
        private profileService: ProfileService,
        private localStorageService: LocalStorageService,
        private toastr: ToastrService,
        private sessioStorageService: SessionStorageService,
    ) { }

    ngOnInit(): void {
        this.studentDetail = this.localStorageService.getDataByKey('student') ? this.localStorageService.getDataByKey('student')
        : this.sessioStorageService.getDataByKey('student');
        this.summaryForm = this.fb.group({
            summary: [this.userDetails.summary],
        });
        // this.modalDataService.setImageViewPopupObserve.subscribe((imageSrc: string) => {
        //   if (!imageSrc) { return; }
        //   this.imagrSrc = imageSrc;
        //   console.log(this.imagrSrc);
        // });
    }

    public closeModel(): void {
        this.modalService.close('add-summary-note');
    }

    /**
     * Updates summary
     */
    public updateSummary() {
        try {
            this.submitted = true;
            this.processing = true;
            if (this.summaryForm.valid) {
                this.profileService.updateProfile(this.studentDetail._id, this.summaryForm.value).subscribe((response) => {
                    this.profileUpdatedEvent.emit(response);
                    this.closeModel();
                    this.processing = false;
                    console.log('summary response', response);
                    this.toastr.success('Summary updated successfully');
                });
            }
        } catch (error) {
            this.toastr.error('Summary updated successfully');
            this.processing = false;
            console.log('error', error);
        }
    }

    /**
     * Gets summary form controls
     */
    get summaryFormControls() {
        return this.summaryForm.controls;
    }

    
  // text editor
  /**
   * on selection changed
   */
  onSelectionChanged = (event) => {
    if (event.oldRange == null) {
      this.onFocus();
    }
    if (event.range == null) {
      this.onBlur();
    }
  };

  /**
   * on focus
   */
  onFocus = () => {
    console.log('On Focus');
  };

  /**
   * on blur
   */
  onBlur = () => {
    console.log('Blurred');
  };
  /**
   * text changed
   *
   * @param event
   */
  textChanged(event) {
    if (event.editor.getLength() > MAX_LENGTH) {
      event.editor.deleteText(MAX_LENGTH, event.editor.getLength());
      this.toastr.error('Character Exceeded Maximum Limit');
    }
  }

}