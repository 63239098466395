/* eslint-disable @typescript-eslint/naming-convention */
// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.dev.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
    production: false,
    // BASE_URL: 'http://cea9-203-223-190-34.ngrok.io',
    API_BASE_URL: 'https://devapi.codespotfoundation.org/api/v1/',
    EVENT_BASE: 'https://www.dev.codespotfoundation.org/app',
    // FILE_UPLOAD: 'http://104.248.15.243:7000/api/v1/upload',
    LOCAL_STORAGE_SECRET: 'D96B2B84E3433063E8355912A845488A',
    ENCRYPT_LOCAL_STORAGE: false,
    GOOGLE_API_KEY: 'AIzaSyBWIomenTvO9o1V8ZfCbQBXV_UG9iDcNsg',
    MAP_KEY: 'AIzaSyDy3904vbrXHNDK91N9rz7W5WN1kZZLCaQ',
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
