/* eslint-disable @typescript-eslint/explicit-function-return-type */
import { Injectable } from '@angular/core';
import { Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { LocalStorageService } from 'app/modules/services/local-storage.service';
import { SessionStorageService } from 'app/modules/services/session-storage.service';

@Injectable({
    providedIn: 'root'
  })
export class UserAuthGuard implements CanActivate {

    constructor(
        private router: Router,
        private localStorageService: LocalStorageService,
        private sessioStorageService: SessionStorageService,
        ) { }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
        if (this.localStorageService.getDataByKey('student') ? this.localStorageService.getDataByKey('student') : this.sessioStorageService.getDataByKey('student')) {
            // logged in so return true
            return true;
        }

        // not logged in so redirect to login page with the return url
        this.router.navigate(['/user-sign-in'], { queryParams: { returnUrl: state.url }});
        return false;
    }
}
