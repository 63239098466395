/* eslint-disable @typescript-eslint/naming-convention */
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { environment } from 'environments/environment';
/**
 * Injectable
 */
@Injectable({
    providedIn: 'root'
})

/**
 * User Dashboard
 */
export class UserDashboardService {
    baseUrl = environment.API_BASE_URL;
    private _data: BehaviorSubject<any> = new BehaviorSubject(null);

    /**
     * Constructor
     */
    constructor(private _httpClient: HttpClient) {
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Accessors
    // -----------------------------------------------------------------------------------------------------

    /**
     * Getter for data
     */
    get data$(): Observable<any> {
        return this._data.asObservable();
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * Get data
     */
    getData(): Observable<any> {
        return this._httpClient.get('api/dashboards/analytics').pipe(
            tap((response: any) => {
                this._data.next(response);
            })
        );
    }

    /**
     * Get event data
     *
     * @param data
     */
    getEventData(data): Observable<any> {
        return this._httpClient.get(this.baseUrl + `admin/event/${data}`).pipe(
            tap((response: any) => {
                this._data.next(response);
            })
        );
    }

    /**
     * apply event
     *
     * @param data
     */
    applyToEvent(data): Observable<any> {
        return this._httpClient.post(this.baseUrl + 'student/register-event', data, { headers: { 'Cache-Control': 'no-cache, no-store, must-revalidate' } }).pipe(
            tap((response: any) => {
                this._data.next(response);
            })
        );
    }

    /**
     * get registered students by event
     *
     * @param data
     */
    registeredUsersByEvent(data): Observable<any> {
        return this._httpClient.get(this.baseUrl + `admin/event/participants/${data}`).pipe(
            tap((response: any) => {
                this._data.next(response);
            })
        );
    }


}
