
 <!--start footer-->
 <section class="footer ">
    <div class="container mx-auto py-2 md:px-8 px-6 z-10 relative  star-right">
        <div class="mx-auto flex gap-6 flex-col sm:flex-row h-full">
            <div class="w-full  md:w-full lg:w-6/12">
                <div class="w-full pr-0 md:pr-20">
                    <img src="assets/logo/code-spot-03.png" class="w-32 mb-9 ">
                    <p class="text-base md:text-xl font-light text-secondary text-start md:text-justify ">We equip
                        students in under-resourced high schools with the skills, experiences, and connections that
                        together create access to careers in technology. We equip students in under-resourced high
                        schools with the skills, experiences, and connections that together create access.</p>
                </div>
            </div>
            <div class="w-full h-full md:w-full lg:w-6/12">
                <div class="mb-5">
                    <h4 class="text-xl md:text-2xl lg:text-3xl font-medium text-primary-color">Subscribe to our <span
                            class="text-orange">Newsletter.</span></h4>
                </div>
                <div class="relative mb-4">
                    <input type="text" id="first_name"
                        class="bg-gray-50 border border-gray-300 text-gray-900 text-xs md:text-xl rounded-lg focus:ring-blue-500 focus:border-orange-500 block w-full py-5 px-5 rounded-full placehoder-text-color "
                        placeholder="Enter mail to receive newsletters " required>
                    <div class="subscribe-btn">
                        <button type="button"
                            class="text-white bg-primary hover:bg-accent border border-solid border-primary rounded-full hover:border-accent focus:outline-none font-regular rounded-lg text-base lg:text-xl flex items-center h-10 sm:h-auto p-1 sm:p-2 px-2 md:py-3 md:px-8 text-center ">Subscribe</button>
                    </div>

                    <div class="w-social-link">
                        <ul class="social_link">
                            <li><a href="https://www.instagram.com/codespotorg/" target="_blank"><img src="assets/images/social/Instagram.svg"
                                        alt="Instagram" width="25" height="25"></a></li>
                            <li><a href="https://www.linkedin.com/company/codespotorg" target="_blank"><img src="assets/images/social/Linkedin.svg"
                                        alt="Linkedin"></a></li>
                            <li><a href="https://www.facebook.com/codespotorg" target="_blank"><img src="assets/images/social/Facebook.svg"
                                        alt="Facebook"></a></li>
                            <li><a href="https://twitter.com/codespotorg" target="_blank"><img src="assets/images/social/Twitter.svg"
                                        alt="Twitter"></a></li>
                            <!-- <li><a href="#" target="_blank"><img src="assets/images/social/GitHub.svg"
                                        alt="Github"></a></li>
                            <li><a href="#" target="_blank"><img src="assets/images/social/Google.svg"
                                        alt="Google"></a></li>
                            <li><a href="#" target="_blank"><img src="assets/images/social/Pinterest.svg"
                                        alt="Pinterest"></a></li> -->
                        </ul>
                    </div>
                </div>

            </div>
        </div>
    </div>
</section>
<section class="footer_links">
    <div class="container mx-auto md:px-8 px-6 ">
        <ul class="">
            <li><a href="https://www.codespotfoundation.org/" target="_blank">Home</a></li>
            <li><a href="https://www.codespotfoundation.org/#programs" target="_blank">Programs</a></li>
            <li><a href="https://www.codespotfoundation.org/#why-codespot" target="_blank">How we train</a></li>
            <li><a href="https://www.codespotfoundation.org/#roadmap" target="_blank">Road map</a></li>
            <li><a href="#" target="_blank">Blog</a></li>
            <li><a href="https://www.codespotfoundation.org/#contact" target="_blank">Contact us</a></li>
            <li><a href="#" target="_blank">Privacy Policy</a></li>
            <li><a href="#" target="_blank">Terms and Conditions</a></li>
        </ul>
    </div>

</section>
<section>
    <div class="container mx-auto md:px-8 px-6 py-4">
        <div class="flex items-center justify-center sm:justify-between flex-wrap  gap-4">
            <div>
                <p class="text-sm md:text-base font-regular text-black">Copyright &#169; {{currentYear}} <a
                        class="text-orange" href="https://www.codespotfoundation.org/" target="_blank">Codespot</a>
                    | <a class="text-orange" href="https://www.sparkouttech.com/" target="_blank">Sparkouttech</a>
                </p>
            </div>
            <div class="flex items-center cursor-pointer" (click)="scrollToTop()"><a
                    class="text-black text-base font-regular"> Back to top </a><span class="pl-2 md:pl-4"><img
                        src="assets/images/event/arrow-right.svg" alt="arrow-right" width="31" height="25"></span>
            </div>
        </div>
    </div>
</section>
<!--end-->
