import { Injectable } from '@angular/core';
import {
    Resolve,
    ActivatedRouteSnapshot,
    RouterStateSnapshot
} from '@angular/router';
import { Observable } from 'rxjs';
import { LocalStorageService } from '../services/local-storage.service';
import { CreateEventService } from '../services/create-event.service';
import { catchError, map } from 'rxjs/operators';

@Injectable({
    providedIn: 'root'
})
export class EventResolverService implements Resolve<any> {
    public eventId: string;
    constructor(
        private localStorageService: LocalStorageService,
        private eventService: CreateEventService,
    ) { }
    resolve(): Observable<any> {
        this.eventId = this.localStorageService.getDataByKey('viewEvent');
        return this.eventService.getEventById(this.eventId);
    }
}
