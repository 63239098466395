<app-common-modal id="edit-profile" class="app-common-modal" [heightClass]="'h-auto'" [widthClass]="'md:w-5/6 mx-auto'">
    <!-- <loading *ngIf="isLoading">{{ isLoading }}</loading> -->

    <div class="relative">
        <div class="w-full">
            <div>
                <h3 class="text-orange text-xl md:text-2xl font-medium">Update Profile</h3>
            </div>
            <div> <img src="assets/images/close.svg"
                    class="absolute right-2 w-8 -mr-6 -mt-8 shadow-md shadow-red-300 rounded-full cursor-pointer" alt=""
                    (click)="closeModel()"></div>
        </div>
        <hr>
        <!--start form-->
        <div class="w-full">

            <form [formGroup]="editBasicDetailsForm" (ngSubmit)="updateBasicDetails()">
                <mat-form-field class="w-full md:w-1/2 pr-2 md:pr-2">
                    <mat-label class="text-[14px] font-regular text-grey">First Name</mat-label>
                    <input id="" matInput formControlName="first_name" placeholder="Enter your First Name">
                    <mat-error class="" *ngIf="editBasicDetailsForm.get('first_name').hasError('required')">
                        First Name is required
                    </mat-error>
                </mat-form-field>
                <mat-form-field class="w-full md:w-1/2">
                    <mat-label class="text-[14px] font-regular text-grey">Last Name</mat-label>
                    <input id="" matInput formControlName="last_name" placeholder="Enter your Last Name">
                    <mat-error class="" *ngIf="editBasicDetailsForm.get('last_name').hasError('required')">
                        Last Name is required
                    </mat-error>
                </mat-form-field>
                <mat-form-field [ngClass]="" class="w-full ">
                    <mat-label class="text-[14px] font-regular text-grey">Phone Number</mat-label>
                    <input matInput formControlName="phone_number" placeholder="Enter your Phone Number"
                        class="pl-4 br-phn w-full" type="text" maxlength="10" oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*?)\..*/g, '$1');">
                    <mat-select  matPrefix [(value)]="selectedCountryCode">
                        <ng-container *ngFor='let country_code of country_codes'>
                            <mat-option [value]="country_code.code">{{country_code.code}}</mat-option>
                        </ng-container> 
                    </mat-select>
                    <mat-error class="" *ngIf="editBasicDetailsForm.get('phone_number').hasError('required')">
                        Phone Number is required
                    </mat-error>
                </mat-form-field>
                <div class="my-2 w-full flex flex-wrap items-center justify-between">
                    <div><button type="button" class="text-white bg-primary hover:bg-accent border border-solid border-primary hover:border-accent focus:outline-none font-regular rounded-lg text-sm sm:text-lg font-medium py-2 px-6 sm:py-2.5 sm:px-8 text-center box-shadow-custom" (click)="reset()">Reset</button></div>
                    <div>
                        <div class="my-4 text-center">
                            <button type="submit" [ngClass]="{'logo-color-bg-disabled px-6 ml-3' :  editBasicDetailsForm.invalid}" [disabled]="editBasicDetailsForm.get('first_name').hasError('required') || editBasicDetailsForm.get('last_name').hasError('required') || editBasicDetailsForm.get('phone_number').hasError('required')" *ngIf="!processing"
                                class="text-white bg-primary hover:bg-accent border border-solid border-primary hover:border-accent focus:outline-none font-regular rounded-lg text-sm sm:text-lg font-medium py-2 px-6 sm:py-2.5 sm:px-8 text-center box-shadow-custom">Update</button>
                            <button type="button" *ngIf="processing"
                                class="text-white cursor-not-allowed bg-primary hover:bg-accent border border-solid border-primary hover:border-accent focus:outline-none font-regular rounded-lg text-sm sm:text-lg font-medium py-2 px-6 sm:py-2.5 sm:px-8 text-center box-shadow-custom">Processing...</button>
                        </div>
                    </div>
                </div>
            </form>
        </div>
        <!--end---->
    </div>
</app-common-modal>
