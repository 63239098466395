/* eslint-disable @typescript-eslint/naming-convention */
/**
 * Grouping file based on their types
 */
export const START_YEAR = [
    { 'year': '2015', 'value': 2015 },
    { 'year': '2016', 'value': 2016 },
    { 'year': '2017', 'value': 2017 },
    { 'year': '2018', 'value': 2018 },
    { 'year': '2019', 'value': 2019 },
    { 'year': '2020', 'value': 2020 },
    { 'year': '2021', 'value': 2021 },
    { 'year': '2022', 'value': 2022 },
    { 'year': '2023', 'value': 2023 },
];

export const END_YEAR = [
    { 'year': '2018', 'value': 2018 },
    { 'year': '2019', 'value': 2019 },
    { 'year': '2020', 'value': 2020 },
    { 'year': '2021', 'value': 2021 },
    { 'year': '2022', 'value': 2022 },
    { 'year': '2023', 'value': 2023 },
    { 'year': '2024', 'value': 2024 },
    { 'year': '2025', 'value': 2025 },
    { 'year': '2026', 'value': 2026 },
    { 'year': '2027', 'value': 2027 },
];

export const GENDER = [
    { 'gender': 'Male', 'value': 'male' },
    { 'gender': 'Female', 'value': 'female' },
    { 'gender': 'Others', 'value': 'others' },
];

export const HOURS = [

    { 'hrs': '00', 'value': 0 },
    { 'hrs': '01', 'value': 1 },
    { 'hrs': '02', 'value': 2 },
    { 'hrs': '03', 'value': 3 },
    { 'hrs': '04', 'value': 4 },
    { 'hrs': '05', 'value': 5 },
    { 'hrs': '06', 'value': 6 },
    { 'hrs': '07', 'value': 7 },
    { 'hrs': '08', 'value': 8 },
    { 'hrs': '09', 'value': 9 },
    { 'hrs': '10', 'value': 10 },
    { 'hrs': '11', 'value': 11 },
    { 'hrs': '12', 'value': 12 },
    { 'hrs': '13', 'value': 13 },
    { 'hrs': '14', 'value': 14 },
    { 'hrs': '15', 'value': 15 },
    { 'hrs': '16', 'value': 16 },
    { 'hrs': '17', 'value': 17 },
    { 'hrs': '18', 'value': 18 },
    { 'hrs': '19', 'value': 19 },
    { 'hrs': '20', 'value': 20 },
    { 'hrs': '21', 'value': 21 },
    { 'hrs': '22', 'value': 22 },
    { 'hrs': '23', 'value': 23 },
];

export const MINUTES = [
    { 'mins': '00', 'value': 0 },
    { 'mins': '01', 'value': 1 },
    { 'mins': '02', 'value': 2 },
    { 'mins': '03', 'value': 3 },
    { 'mins': '04', 'value': 4 },
    { 'mins': '05', 'value': 5 },
    { 'mins': '06', 'value': 6 },
    { 'mins': '07', 'value': 7 },
    { 'mins': '08', 'value': 8 },
    { 'mins': '09', 'value': 9 },
    { 'mins': '10', 'value': 10 },
    { 'mins': '11', 'value': 11 },
    { 'mins': '12', 'value': 12 },
    { 'mins': '13', 'value': 13 },
    { 'mins': '14', 'value': 14 },
    { 'mins': '15', 'value': 15 },
    { 'mins': '16', 'value': 16 },
    { 'mins': '17', 'value': 17 },
    { 'mins': '18', 'value': 18 },
    { 'mins': '19', 'value': 19 },
    { 'mins': '20', 'value': 20 },
    { 'mins': '21', 'value': 21 },
    { 'mins': '22', 'value': 22 },
    { 'mins': '23', 'value': 23 },
    { 'mins': '24', 'value': 24 },
    { 'mins': '25', 'value': 25 },
    { 'mins': '26', 'value': 26 },
    { 'mins': '27', 'value': 27 },
    { 'mins': '28', 'value': 28 },
    { 'mins': '29', 'value': 29 },
    { 'mins': '30', 'value': 30 },
    { 'mins': '31', 'value': 31 },
    { 'mins': '32', 'value': 32 },
    { 'mins': '33', 'value': 33 },
    { 'mins': '34', 'value': 34 },
    { 'mins': '35', 'value': 35 },
    { 'mins': '36', 'value': 36 },
    { 'mins': '37', 'value': 37 },
    { 'mins': '38', 'value': 38 },
    { 'mins': '39', 'value': 39 },
    { 'mins': '40', 'value': 40 },
    { 'mins': '41', 'value': 41 },
    { 'mins': '42', 'value': 42 },
    { 'mins': '43', 'value': 43 },
    { 'mins': '44', 'value': 44 },
    { 'mins': '45', 'value': 45 },
    { 'mins': '46', 'value': 46 },
    { 'mins': '47', 'value': 47 },
    { 'mins': '48', 'value': 48 },
    { 'mins': '49', 'value': 49 },
    { 'mins': '50', 'value': 50 },
    { 'mins': '51', 'value': 51 },
    { 'mins': '52', 'value': 52 },
    { 'mins': '53', 'value': 53 },
    { 'mins': '54', 'value': 54 },
    { 'mins': '55', 'value': 55 },
    { 'mins': '56', 'value': 56 },
    { 'mins': '57', 'value': 57 },
    { 'mins': '58', 'value': 58 },
    { 'mins': '59', 'value': 59 },

];

export const COUNTRY_CODES = [
    { country: 'IND', code: '+91' },
    { country: 'USA', code: '+1' },
    { country: 'UK', code: '+44' },
    { country: 'AUS', code: '+61' },
    { country: 'GER', code: '+49' },
    { country: 'FRA', code: '+33' },
    { country: 'CHN', code: '+86' },
    { country: 'JPN', code: '+81' },
    { country: 'BRA', code: '+55' }
  ];
  