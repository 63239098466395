/* eslint-disable @typescript-eslint/explicit-function-return-type */
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { LocalStorageService } from 'app/modules/services/local-storage.service';
import { SessionStorageService } from 'app/modules/services/session-storage.service';

@Injectable({
    providedIn: 'root'
})
export class UserAuthService {

    constructor(
        private router: Router,
        private localStorageService: LocalStorageService,
        private sessionStorageService: SessionStorageService
    ) { }

    /**
     * Logouts user auth service
     */
    public logout() {
        this.localStorageService.removeAllLocalStorage();
        this.sessionStorageService.removeAllLocalStorage();
        // Clear user session or token
        this.router.navigate(['/user-sign-in']);
    }


}
