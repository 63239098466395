<!-- !Need to adapt to pdf view if possible -->


<app-common-modal id="view-image-modal" class="app-common-modal" [heightClass]="'h-auto'" [widthClass]="'md:w-3/6 mx-auto'">
  <loading *ngIf="isLoading">{{ isLoading }}</loading>

  <div class="relative" *ngIf="imagrSrc">
    <img src="assets/images/close.svg"
      class="absolute right-2 w-8 -mr-6 -mt-4 shadow-md shadow-red-300 rounded-full cursor-pointer" alt=""
      (click)="closeModel()">

    <img src="{{imagrSrc}}" alt="">
    <!-- <app-image-component [image]="imagrSrc" [class]="'w-full h-full '">
    </app-image-component> -->
  </div>
</app-common-modal>