

<app-common-modal id="add-certificate" class="app-common-modal" [heightClass]="'h-auto'" [widthClass]="'w-full md:w-3/6 mx-auto'">
    <!-- <loading *ngIf="isLoading">{{ isLoading }}</loading> -->

    <div class="relative">
     <div class="w-full">
        <div><h3 class="text-orange text-xl md:text-2xl font-medium"></h3></div>
        <div> <img src="assets/images/close.svg"
            class="absolute right-2 w-8 -mr-6 -mt-4 shadow-md shadow-red-300 rounded-full cursor-pointer" alt=""
            (click)="closeModel()"></div>
     </div>
     <div class="w-full flex flex-col items-center justify-center flex-wrap">
        <div>
            <h3 class="text-center text-btn-color text-xl md:text-2xl font-medium mb-4">Build your profile using Certificates</h3>
        </div>
        <div class="px-8 text-center">
            <img src="assets/images/event/1.png" alt="" class=" w-full h-auto md:w-64 md:h-36 mb-6">
        </div>
        <div class="text-center">
            <p class="text-base text-accent-200 font-light text-center mb-4">Now you can add your CodeSpot’s completion certificate in</p>
        </div>
        <div><img src="assets/images/certificate/linkedin.svg" alt="" class="w-28 h-10 object-contain mb-6"></div>
        <div><button type="button"  class="text-primary-400  hover:bg-accent border border-solid border-primary hover:text-white hover:border-accent focus:outline-none font-regular rounded-lg text-sm lg:text-base flex items-center h-8 sm:h-auto p-1 sm:p-2 px-4 md:py-2 md:px-6 text-center">Add Now</button></div>
     </div>

    </div>
  </app-common-modal>
