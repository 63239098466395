/* eslint-disable @typescript-eslint/member-ordering */
/* eslint-disable @typescript-eslint/naming-convention */
/* eslint-disable @typescript-eslint/ban-types */
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { LocalStorageService } from './local-storage.service';
import { ObservableEventInterface } from 'app/core/interface/common.interface';
import { environment } from 'environments/environment';

@Injectable({
    providedIn: 'root',
})
/**
 * modal data service
 */
export class ModalDataService {
    private BASE_URL: string;

    // image observables
    private setImageViewPopup: any;
    public setImageViewPopupObserve: Observable<string>;


    // current modal status
    private currentModal: any;
    public currentModalStatusObservable: Observable<string>;

    // common observable service
    private commonSubscriber: any;
    public commonSubscriberObservable: Observable<any>;

    // qr code
    private qrCode: any;
    public qrCodeObserve: Observable<string>;



    /**
     * @constructor
     */
    constructor(
        private http: HttpClient,
        private localStorageService: LocalStorageService,
    ) {
        this.BASE_URL = environment.API_BASE_URL;


        // set view popup image
        this.setImageViewPopup = new BehaviorSubject('');
        this.setImageViewPopupObserve = this.setImageViewPopup.asObservable();


        // send modal current status
        this.currentModal = new BehaviorSubject(null);
        this.currentModalStatusObservable = this.currentModal.asObservable();

        // qr code
        this.qrCode = new BehaviorSubject('');
        this.qrCodeObserve = this.qrCode.asObservable();

    }

    /**
     * Send modal current Status
     *
     * @param currentStatus
     */
    public sendCurrentModalStatus(currentStatus: string): void {
        this.currentModal.next(currentStatus);
    }


    /**
     * Set image on image view modal
     *
     * @param data
     */
    public setImageViewPopupTrigger(data: string): void {
        this.setImageViewPopup.next(data);
    }

    /**
     * qr code
     *
     * @param data
     */
    public setQrCodeUrl(data: string): void {
        console.log(data)
        this.qrCode.next(data);
    }

}
