

<app-common-modal id="add-summary-note" class="app-common-modal" [heightClass]="'h-auto'" [widthClass]="'md:w-5/6 mx-auto'">
    <!-- <loading *ngIf="isLoading">{{ isLoading }}</loading> -->

    <div class="relative">
     <div class="w-full">
        <div><h3 class="text-orange text-xl md:text-2xl font-medium">Summary</h3></div>
        <div> <img src="assets/images/close.svg"
            class="absolute right-2 w-8 -mr-6 -mt-8 shadow-md shadow-red-300 rounded-full cursor-pointer" alt=""
            (click)="closeModel()"></div>
     </div>
     <hr>
     <!--start form-->
     <div class="w-full">
        <label class="text-base md:text-lg font-regular text-grey leading-6 mb-4">Tell us about your years of experience, industry, or skills. People also talk about their achievements or previous job experiences.</label>
        <form [formGroup]="summaryForm" (ngSubmit)="updateSummary()">
            <!-- <textarea formControlName="summary" class="border border-slate-200 rounded-md text-base md:text-lg font-regular w-full h-30 mt-4 mb-2"> </textarea> -->
            <quill-editor [styles]="{height: '150px'}" [modules]="quillConfig" formControlName='summary'
            class="border border-slate-200 rounded-md text-base md:text-lg font-regular w-full h-30 mt-4 mb-2"
                (onSelectionChanged)="onSelectionChanged($event)"
                (onContentChanged)="textChanged($event)"></quill-editor>
            <!-- <div class="input--error" *ngIf="(summaryFormControls.summary.touched || submitted) && summaryFormControls.summary.errors?.required">
                This field is required *
              </div> -->

        <p class="text-grey font-regular text-md text-right">210/2000</p>
        <div class="my-4 text-center">
            <button type="submit" *ngIf="!processing" class="text-white bg-primary hover:bg-accent border border-solid border-primary hover:border-accent focus:outline-none font-regular rounded-lg text-sm sm:text-lg font-medium py-2 px-6 sm:py-2.5 sm:px-8 text-center box-shadow-custom">Update</button>
            <button type="button" *ngIf="processing" class="text-white cursor-not-allowed bg-primary hover:bg-accent border border-solid border-primary hover:border-accent focus:outline-none font-regular rounded-lg text-sm sm:text-lg font-medium py-2 px-6 sm:py-2.5 sm:px-8 text-center box-shadow-custom">Processing...</button>
        </div>
    </form>
     </div>
     <!--end---->
    </div>
  </app-common-modal>