import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from 'environments/environment';

@Injectable({
    providedIn: 'root'
})
/**
 * user listing
 */
export class UserListingService {
    public baseUrl = environment.API_BASE_URL;

    /**
     * Constructor
     */
    constructor(
        private _httpClient: HttpClient,

    ) {

    }


    /**
     * get All user
     *
     * @return{Observable}
     */
    getAllUser(): Observable<any> {
        return this._httpClient.get(this.baseUrl + 'admin/students');
    }

    /**
     * get user by id
     *
     * @param data
     * @return{Observable}
     */
    getUserById(data): Observable<any> {
        return this._httpClient.get(this.baseUrl + `admin/student?id=${data}`);
    }

    /**
     * update user by id
     *
     * @param data
     * @param id
     * @return{Observable}
     */
    updateUserDetails(data?: any, id?: string): Observable<any> {
        return this._httpClient.patch(this.baseUrl + `student/${id}`, data);
    }

    /**
     * delete
     *
     * @return{Observable}
     */
    // deleteEvent(): Observable<any> {
    //     const data = '';
    //     return this._httpClient.delete(this.baseUrl + `students/${data}`);
    // }


}
