<app-common-modal id="upload-profile-image" class="app-common-modal" [heightClass]="'h-auto'"
  [widthClass]="'md:w-3/6 mx-auto'">
  <div class="w-full">
    <div>
      <h3 class="text-orange text-xl md:text-2xl font-medium mb-3">{{pageHeader}}</h3>
    </div>
    <div class="relative">
      <img src="assets/images/close.svg"
        class="absolute right-2 w-8 -mr-6 -mt-12 shadow-md shadow-red-300 rounded-full cursor-pointer" alt=""
        (click)="closeModel()">
    </div>
  </div>
  <!--add or upload photo-->
  <div class="w-full" *ngIf="!selectedImage && !imageToEdit">
    <img src="assets/images/avatars/demo-1.png" alt="" class="w-40 h-40 object-contain mb-4 mx-auto rounded-full">
    <input #uploadImage type="file" single accept="image/png, image/gif, image/jpeg" (change)="onSelectImage($event)"
      hidden>
    <div class="flex items-center justify-between">
      <button type="button" (click)="onCancelUpload()"
        class="text-red-500 border border-red-300 font-regular rounded-lg text-sm lg:text-base flex items-center hover:bg-red-500 hover:text-white h-8 sm:h-auto p-1 sm:p-2 px-4 md:py-2 md:px-6 text-center">Cancel</button>
      <button type="button"
        class="text-white bg-primary hover:bg-accent border border-solid border-primary hover:border-accent focus:outline-none  rounded-lg text-sm lg:text-base font-normal lex items-center py-2 px-6  sm:px-8 text-center"
        (click)="uploadImage.click()">Upload</button>
    </div>
  </div>
  <!--end-->
  <!--upload View-->
  <div class="w-full" *ngIf="selectedImage && !imageToEdit">
    <img [src]="selectedImage" alt="" class="w-40 h-40 object-contain mb-4 mx-auto rounded-full">
    <div class="flex items-center justify-between">
      <button type="button" *ngIf="!isFileUploaded"
        class="text-red-500 border border-red-300 font-regular rounded-lg text-sm lg:text-base flex items-center hover:bg-red-500 hover:text-white h-8 sm:h-auto p-1 sm:p-2 px-4 md:py-2 md:px-6 text-center"
        (click)="removeSelectedImage()">Cancel</button>
      <button type="button" *ngIf="!isFileUploaded"
        class="text-white bg-primary hover:bg-accent border border-solid border-primary hover:border-accent focus:outline-none  rounded-lg text-sm lg:text-base font-normal  items-center py-2 px-6  sm:px-8 text-center"
        (click)="onSaveImage()">Save
        Photo</button>
    </div>
  </div>
  <!--end-->
  <!--edit photo view-->
  <div class="w-full" *ngIf="imageToEdit">
    <img [src]="imageToEdit? imageToEdit : 'assets/images/avatars/demo-1.png'" alt=""
      class="w-40 h-40 object-contain mb-4 mx-auto rounded-full">
    <input #uploadImage type="file" single accept="image/png, image/gif, image/jpeg" (change)="onSelectImage($event)"
      hidden>
    <div class="flex items-center justify-between">
      <button type="button" (click)="onRemoveProfileImage()"
        class="text-red-500 border border-red-300 font-regular rounded-lg text-sm lg:text-base flex items-center hover:bg-red-500 hover:text-white h-8 sm:h-auto p-1 sm:p-2 px-4 md:py-2 md:px-6 text-center">Delete
        Photo</button>
      <button type="button" (click)="uploadImage.click()"
        class=" text-white bg-primary hover:bg-accent border border-solid border-primary hover:border-accent
        focus:outline-none rounded-lg text-sm lg:text-base font-normal lex items-center py-2 px-6 sm:px-8 text-center ">Edit</button>
      <button type=" button" (click)="uploadImage.click()"
        class=" text-white bg-primary hover:bg-accent border border-solid border-primary hover:border-accent
        focus:outline-none rounded-lg text-sm lg:text-base font-normal lex items-center py-2 px-6 sm:px-8 text-center ">Add
        Photo</button>
    </div>
  </div>
  <!--end-->
</app-common-modal>