/* eslint-disable @typescript-eslint/member-ordering */
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnDestroy, OnInit, TemplateRef, ViewChild, ViewContainerRef, ViewEncapsulation } from '@angular/core';
import { Router } from '@angular/router';
import { BooleanInput } from '@angular/cdk/coercion';
import { Subject, Subscription } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { User } from 'app/core/user/user.types';
import { UserService } from 'app/core/user/user.service';
import { MatButton } from '@angular/material/button';
import { Overlay, OverlayRef } from '@angular/cdk/overlay';
import { TemplatePortal } from '@angular/cdk/portal';
import { LocalStorageService } from 'app/modules/services/local-storage.service';
import { CommonService } from 'app/modules/services/common.service';
import { SessionStorageService } from 'app/modules/services/session-storage.service';

@Component({
    selector: 'user-user',
    templateUrl: './user.component.html',
    encapsulation: ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.OnPush,
    exportAs: 'user-user'
})
export class UserUserComponent implements OnInit, OnDestroy {
    /* eslint-disable @typescript-eslint/naming-convention */
    static ngAcceptInputType_showAvatar: BooleanInput;
    /* eslint-enable @typescript-eslint/naming-convention */

    @Input() showAvatar: boolean = true;
    user: User;

    private _unsubscribeAll: Subject<any> = new Subject<any>();
    @ViewChild('messagesOrigin') private _messagesOrigin: MatButton;
    @ViewChild('messagesPanel') private _messagesPanel: TemplateRef<any>;
    private _overlayRef: OverlayRef;
    public studentDetail: any;
    public profileImage: any;
    public profileImageObserve: Subscription;
    /**
     * Constructor
     */
    constructor(
        private _changeDetectorRef: ChangeDetectorRef,
        private _router: Router,
        private _userService: UserService,
        private _overlay: Overlay,
        private _viewContainerRef: ViewContainerRef,
        private localStorageService: LocalStorageService,
        private sessioStorageService: SessionStorageService,
        private commonService: CommonService,
    ) {
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Lifecycle hooks
    // -----------------------------------------------------------------------------------------------------

    /**
     * On init
     */
    ngOnInit(): void {
        this.studentDetail = this.localStorageService.getDataByKey('student') ? this.localStorageService.getDataByKey('student')
        : this.sessioStorageService.getDataByKey('student');
        this.profileImage = this.localStorageService.getDataByKey('student').profile_picture ? this.localStorageService.getDataByKey('student').profile_picture
        : this.sessioStorageService.getDataByKey('student').profile_picture;
        this.profileImageObserve = this.commonService.profileImageObserve.subscribe((data) => {
            if (data) {
                this.profileImage = data;
                this._changeDetectorRef.detectChanges();
            }
        });
        // Subscribe to user changes
        this._userService.user$
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe((user: User) => {
                this.user = user;

                // Mark for check
                this._changeDetectorRef.markForCheck();
            });
    }

    /**
     * On destroy
     */
    ngOnDestroy(): void {
        // Unsubscribe from all subscriptions
        this._unsubscribeAll.next();
        this._unsubscribeAll.complete();
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * Update the user status
     *
     * @param status
     */
    updateUserStatus(status: string): void {
        // Return if user is not available
        if (!this.user) {
            return;
        }

        // Update the user
        this._userService.update({
            ...this.user,
            status
        }).subscribe();
    }

    /**
     * Sign out
     */
    onUserSignOut(): void {
        this._router.navigate(['/user-sign-out']);
    }

    public profilePageNavigation(): void {
        this._router.navigate(['/user-profile']);
    }

    // /**
    //  * Open the messages panel
    //  */
    // openPanel(): void {
    //     // Return if the messages panel or its origin is not defined
    //     if (!this._messagesPanel || !this._messagesOrigin) {
    //         return;
    //     }

    //     // Create the overlay if it doesn't exist
    //     if (!this._overlayRef) {
    //         this._createOverlay();
    //     }

    //     // Attach the portal to the overlay
    //     this._overlayRef.attach(new TemplatePortal(this._messagesPanel, this._viewContainerRef));
    // }

    // /**
    //  * Close the messages panel
    //  */
    // closePanel(): void {
    //     this._overlayRef.detach();
    // }

    // /**
    //  * Create the overlay
    //  */
    // private _createOverlay(): void {
    //     // Create the overlay
    //     this._overlayRef = this._overlay.create({
    //         hasBackdrop: true,
    //         backdropClass: 'fuse-backdrop-on-mobile',
    //         scrollStrategy: this._overlay.scrollStrategies.block(),
    //         positionStrategy: this._overlay.position()
    //             .flexibleConnectedTo(this._messagesOrigin._elementRef.nativeElement)
    //             .withLockedPosition(true)
    //             .withPush(true)
    //             .withPositions([
    //                 {
    //                     originX: 'start',
    //                     originY: 'bottom',
    //                     overlayX: 'start',
    //                     overlayY: 'top'
    //                 },
    //                 {
    //                     originX: 'start',
    //                     originY: 'top',
    //                     overlayX: 'start',
    //                     overlayY: 'bottom'
    //                 },
    //                 {
    //                     originX: 'end',
    //                     originY: 'bottom',
    //                     overlayX: 'end',
    //                     overlayY: 'top'
    //                 },
    //                 {
    //                     originX: 'end',
    //                     originY: 'top',
    //                     overlayX: 'end',
    //                     overlayY: 'bottom'
    //                 }
    //             ])
    //     });

    //     // Detach the overlay from the portal on backdrop click
    //     this._overlayRef.backdropClick().subscribe(() => {
    //         this._overlayRef.detach();
    //     });
    // }
}
