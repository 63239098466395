import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { CreateEventService } from 'app/modules/services/create-event.service';
import { ModalService } from 'app/modules/services/modal.service';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-confirmation-modal',
  templateUrl: './confirmation-modal.component.html',
  styleUrls: ['./confirmation-modal.component.scss']
})
export class ConfirmatioModalComponent implements OnInit {
  @Input() Id: string = '';
  @Input() Title: string = '';
  @Input() message: string = '';
  @Input() Type : string = ''; //[Event, Speaker]
  @Output() confirmation = new EventEmitter(false);

  /**
   * constructor
   *
   */
  constructor(
    private modalService: ModalService,
    private eventService: CreateEventService,
    private route: Router,
    private toastr: ToastrService,
  ) { }

  /**
   * initial loader
   */
  ngOnInit(): void {
  }

  /**
   * close modal
   */
  public closeModel(): void {
    this.modalService.close('confirmation-modal');
  }

  /**
   * delete 
   */
  public onConfirmation(): void {
    this.confirmation.emit({ confirmation: true, id: this.Id });
  }

}
