/* eslint-disable @typescript-eslint/prefer-for-of */
/* eslint-disable @angular-eslint/use-lifecycle-interface */
/* eslint-disable @typescript-eslint/naming-convention */
/* eslint-disable @typescript-eslint/explicit-function-return-type */
import { Component, EventEmitter, HostListener, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import { CollegeListing } from 'app/core/interface/common.interface';
import { END_YEAR, START_YEAR } from 'app/modules/constant/values.const';
import { DataProviderService } from 'app/modules/services/data-provider.service';
import { LocalStorageService } from 'app/modules/services/local-storage.service';
import { ModalDataService } from 'app/modules/services/modal-data.service';
import { ModalService } from 'app/modules/services/modal.service';
import { SessionStorageService } from 'app/modules/services/session-storage.service';
import { ProfileService } from 'app/modules/user/student-profile/services/profile.service';
import { ToastrService } from 'ngx-toastr';
import { Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';

@Component({
    selector: 'app-add-qualifiation',
    templateUrl: './add-qualifiation.component.html',
    styleUrls: ['./add-qualifiation.component.scss']
})
export class AddQualifiationComponent implements OnInit {
    @HostListener('click', ['$event'])
    @Input() userDetails: any;
    @Output() profileUpdatedEvent = new EventEmitter<any>();
    public imagrSrc: string;
    public fileGrouping: any;
    isLoading = false;
    public qualificationForm: FormGroup | any;
    public degrees: any = [];
    public studentDetail: any = null;
    public submitted: boolean = false;
    public processing: boolean = false;
    public selectedCollegeLogo: string;
    public colleges: CollegeListing[] = [];
    public collegeListArray: Array<any> = [];
    public filteredOptions: Observable<CollegeListing[]>;
    public selectedCollegeId: any = '';
    public startYear: any = [];
    public endYear: any = [];
    public datalistenerObservable: any;
    public selectedStartYear: any;
    public selectedEndYear: any;
    public selectedDegree: any;
    public isUpdateQualification: boolean = false;
    public qualificationId: string = '';

    constructor(
        private modalService: ModalService,
        private modalDataService: ModalDataService,
        private profileService: ProfileService,
        private toastr: ToastrService,
        private fb: FormBuilder,
        private localStorageService: LocalStorageService,
        private dataServiceProvider: DataProviderService,
        private sessioStorageService: SessionStorageService,
    ) { }

    async ngOnInit() {
        this.studentDetail = this.localStorageService.getDataByKey('student') ? this.localStorageService.getDataByKey('student')
        : this.sessioStorageService.getDataByKey('student');
        this.qualificationForm = this.fb.group({
            college_name: ['', [Validators.required]],
            // qualification: ['', [Validators.required]],
            degree: ['', [Validators.required]],
            department: ['', [Validators.required]],
            start_year: ['', [Validators.required]],
            end_year: ['', [Validators.required]],
        });
        //get college list
        this.getAllColleges();
        this.getFormDetails();

    }

    onClick(event: any) {
        // get the clicked element
        if (event.target.ariaLabel && event.target.ariaLabel.includes('Previous month')) {
          console.log('previous');
        } else if (event.target.ariaLabel && event.target.ariaLabel.includes('Next month')) {
          console.log('next');
        }
      }

    ngOnDestroy() {
        this.datalistenerObservable.unsubscribe();
    }

    /**
     * Gets form details
     */
    public async getFormDetails(){
        this.datalistenerObservable = this.dataServiceProvider.dataListener.subscribe(async (response) => {
            if (response?.component === 'student-profile' && response.type === 'edit') {
                this.isUpdateQualification = true;
                this.qualificationId = response.qualificationId;
                const qualification = this.userDetails.educational_qualifications;
                for (let index = 0; index < qualification.length; index++) {
                    const element = qualification[index];
                    if (response.qualificationId === element._id) {
                        this.qualificationForm.patchValue({
                            department: element.department,
                            degree: element.degree,
                            start_year: element.start_year,
                            end_year: element.end_year
                        });
                        this.selectedStartYear = element.start_year;
                        this.selectedEndYear = element.end_year;
                        this.selectedCollegeLogo = element.college_name.college_icon;
                        this.selectedDegree = element.degree._id;
                        this.qualificationForm.get('college_name').setValue(element.college_name);
                    }
                }
            } else {
                this.qualificationForm.get('college_name').setValue('');
                this.qualificationForm.get('department').setValue('');
                const degree: any = await this.getDegree().catch((error) => {
                    this.toastr.error(error.data.message);
                });
                this.degrees = await degree.data;
                this.startYear = START_YEAR;
                this.endYear = END_YEAR;
                this.selectedStartYear = this.startYear[0].value;
                this.selectedEndYear = this.endYear[0].value;
                this.selectedDegree = this.degrees[0]._id;
                this.qualificationForm.patchValue({
                    degree: this.selectedDegree,
                    start_year: this.selectedStartYear,
                    end_year: this.selectedEndYear
                });
                this.selectedCollegeLogo = '';
                this.qualificationId = '';
            }
        });
    }
/**
 * Resets add qualifiation component
 */
public reset(){
    this.qualificationForm.reset();
}
    public updateInpuValue() {
        // console.log('updateInputValue', this.qualificationId);

    }

    /**
     * display college list
     *
     * @param user
     * @returns
     */
    public displayFn(user: CollegeListing): string {
        return user && user.college ? user.college : '';
    }

    public onChangeStartYear(event: any): void {
        this.qualificationForm.patchValue({ start_year: event.value });

    }

    public onChangeEndYear(event: any): void {
        this.qualificationForm.patchValue({ end_year: event.value });

    }

    /**
     * Gets qualification form controls
     */
    get qualificationFormControls() {
        return this.qualificationForm.controls;
    }

    public closeModel(): void {
        this.modalService.close('add-qualification');
        this.selectedStartYear = this.startYear[0].value;
        this.selectedEndYear = this.endYear[0].value;
        this.selectedDegree = this.degrees[0]._id;
        this.selectedCollegeLogo = '';
        this.isUpdateQualification = false;
        this.qualificationId = '';
    }

    /**
     * Gets degree
     *
     * @returns
     */
    public async getDegree() {
        return new Promise((resolve, reject) => {
            this.profileService.getDegree().subscribe({
                next: async (response: any) => {
                    console.log('hello');
                    resolve({ status: true, data: response.data });
                },
                error: (error) => {
                    reject({ status: false, data: error });
                },
            });
        });
    }

    /**
     * Determines whether change degree on
     *
     * @param event
     */
    public onChangeDegree(event: any) {
        console.log('onChangeDegree', event);
        if (event.value !== '') {
            this.qualificationForm.patchValue({ degree: event.value });
        } else {
            this.toastr.error('Something went wrong try again');
        }
    }

    /**
     * Updates qualification
     */
    public updateQualification() {
        try {
            if(this.qualificationForm.value.start_year >= this.qualificationForm.value.end_year){
                this.toastr.error('Start year should be less than End year');
                return;
            }
            this.submitted = true;
            this.processing = true;
            let degreeId;
            if (this.qualificationForm.value.degree._id !== undefined) {
                degreeId = this.qualificationForm.value.degree._id;
            } else {
                degreeId = this.qualificationForm.value.degree;
            }
            const param = {
                student_id: this.studentDetail._id,
                college_name: this.qualificationForm.value.college_name._id,
                degree: degreeId,
                department: this.qualificationForm.value.department,
                start_year: this.qualificationForm.value.start_year,
                end_year: this.qualificationForm.value.end_year,
            };
            console.log('qualification form', this.qualificationForm, 'status', this.isUpdateQualification);
            if (this.qualificationForm.valid) {
                if (this.isUpdateQualification) {
                    this.profileService.updateQualification(this.qualificationId, param).subscribe((response) => {
                        console.log('update qualification response', response);
                        this.profileUpdatedEvent.emit(response);
                        this.processing = false;
                        this.closeModel();
                        this.toastr.success('Qualification updated successfully');
                    });
                } else {
                    console.log('create spot', param);
                    this.profileService.addQualification(param).subscribe((response) => {
                        this.profileUpdatedEvent.emit(response);
                        this.processing = false;
                        this.closeModel();
                        this.toastr.success('Qualification created successfully');
                    });
                }
            }
        } catch (error) {
            this.processing = false;
            this.toastr.error('something went wrong');
            console.log('error', error);
        }
    }

    public getAllColleges() {
        this.profileService.getAllColleges().subscribe((response) => {
            this.colleges = response.data;
            this.collegeListArray = response.data;
        });
    }

    /**
     * searching college
     *
     *@param data
     */
    public onSearchCollege(data: any): void {
        // this.profileService.getColleges(data?.target?.value).subscribe((res) => {
        // this.colleges = res?.data;

        // filter options
        this.filteredOptions = this.qualificationForm.get('college_name').valueChanges.pipe(
            startWith(''),
            map((value: any) => {
                const name = typeof value === 'string' ? value : value?.name;
                return name ? this._filter(name as string) : this.colleges.slice();
            }),
        );
        // });
        if (!data?.target?.value) {
            this.selectedCollegeLogo = '';
            this.selectedCollegeId = '';
        }

    }

    /**
     * select college
     *
     * @param event
     */
    public onSelectCollege(event: MatAutocompleteSelectedEvent): void {
        if (event) {
            this.selectedCollegeLogo = event.option.value.college_icon;
            this.selectedCollegeId = event.option.value._id;
            console.log(this.selectedCollegeId);
        }

    }

    /**
     * filter list
     *
     * @param name
     * @returns
     */
    private _filter(name: string): CollegeListing[] {
        const filterValue = name.toLowerCase();

        return this.colleges = this.collegeListArray.filter(option => option.college.toLowerCase().includes(filterValue));
    }
}
