/* eslint-disable @typescript-eslint/explicit-function-return-type */
/* eslint-disable @typescript-eslint/naming-convention */
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { DataProviderService } from 'app/modules/services/data-provider.service';
import { ModalDataService } from 'app/modules/services/modal-data.service';
import { ModalService } from 'app/modules/services/modal.service';
import { ProfileService } from 'app/modules/user/student-profile/services/profile.service';
import { ToastrService } from 'ngx-toastr';
import { COUNTRY_CODES } from 'app/modules/constant/values.const';

@Component({
    selector: 'app-edit-profile',
    templateUrl: './edit-profile.component.html',
    styleUrls: ['./edit-profile.component.scss'],
})
export class EditProfileComponent implements OnInit {
    @Input() userDetails: any;
    @Output() profileUpdatedEvent = new EventEmitter<any>();
    public imagrSrc: string;
    public fileGrouping: any;
    isLoading = false;
    public editBasicDetailsForm: FormGroup | any;
    public submitted: boolean = false;
    public processing: boolean = false;
    public country_codes = COUNTRY_CODES;
    public countryCode = '';
    public selectedCountryCode;
    public phoneNumber;

    constructor(
        private modalService: ModalService,
        private modalDataService: ModalDataService,
        private profileService: ProfileService,
        private fb: FormBuilder,
        private toastr: ToastrService,
        private dataServiceProvider: DataProviderService
    ) {}

    ngOnInit(): void {
        console.log('edit profile component', this.userDetails);
        this.getProfileDetails();   
    }

    /**
     * Gets profile details
     */
    public getProfileDetails() {
        this.phoneNumber = this.userDetails.phone_number.slice(this.userDetails.phone_number.length-10)
        this.editBasicDetailsForm = this.fb.group({
            first_name: [this.userDetails.first_name, [Validators.required]],
            last_name: [this.userDetails.last_name, [Validators.required]],
            phone_number: [
                this.phoneNumber,
                [Validators.required,Validators.minLength(10)],
            ],
        });
        this.selectedCountryCode = this.userDetails.phone_number.slice(0,this.userDetails.phone_number.length - 10)
    }

    /**
     * Resets edit profile component
     */
    public reset() {
        this.editBasicDetailsForm.reset();
    }
    /**
     * Gets user basic details form controls
     */
    get userBasicDetailsFormControls() {
        return this.editBasicDetailsForm.controls;
    }

    public closeModel(): void {
        this.getProfileDetails()
        this.modalService.close('edit-profile');
    }

    /**
     * Updates basic details
     */
    public updateBasicDetails() {
        try {
            this.submitted = true;
            this.processing = true;
            console.log(this.selectedCountryCode);
            const countryCode = this.selectedCountryCode ? this.selectedCountryCode : '+91';
            if (this.editBasicDetailsForm.valid) {
                this.profileService
                    .updateProfile(
                        this.userDetails._id,{...this.editBasicDetailsForm.value,
                            phone_number : countryCode + '' + this.editBasicDetailsForm.value.phone_number
                        }
                        )
                    .subscribe((response) => {
                        const data = {
                            component: 'update-profile',
                        };
                        // emit response for parent component
                        this.profileUpdatedEvent.emit(response);
                        this.dataServiceProvider.dataEmit(data);
                        this.processing = false;
                        this.closeModel();
                        this.toastr.success('Profile updated successfully');
                    });
            }
        } catch (error) {
            this.processing = false;
            this.toastr.error('something went wrong');
            console.log('error', error);
        }
    }
}
