import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpEvent } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from 'environments/environment';

@Injectable({
    providedIn: 'root',
})
/**
 * File Upload Service
 */
export class FileUploadService {
    public baseUrl = environment.API_BASE_URL;
    // public fileUploadUrl = environment.FILE_UPLOAD;

    /**
     *  constructor
     */
    constructor(private http: HttpClient,) { }

    /**
     *
     * @param file
     * @param numberOfFiles
     * @return
     */
    public uploadFile(file: File, numberOfFiles: number): Observable<HttpEvent<any>> {
        const formData: FormData = new FormData();
        formData.append('media', file);
        const req = new HttpRequest('POST', numberOfFiles > 1 ? '' : `${this.baseUrl}upload`, formData, {
            reportProgress: true,
            responseType: 'json',
        });
        return this.http.request(req);
    }

    /**
     * delete file
     *
     * @param data
     */
    public deleteFile(data: any): Observable<any> {
        return this.http.delete(this.baseUrl + 'delete-file', { body: { 'url': data } });
    }

}
