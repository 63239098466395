<app-common-modal id="add-qualification" class="app-common-modal" [heightClass]="'h-auto'" [widthClass]="'md:w-5/6 mx-auto'">
    <!-- <loading *ngIf="isLoading">{{ isLoading }}</loading> -->

    <div class="relative">
        <div class="w-full">
            <div>
                <h3 class="text-orange text-xl md:text-2xl font-medium">Educational Qualifications</h3>
            </div>
            <div> <img src="assets/images/close.svg"
                    class="absolute right-2 w-8 -mr-6 -mt-8 shadow-md shadow-red-300 rounded-full cursor-pointer" alt=""
                    (click)="closeModel()"></div>
        </div>
        <hr>
        <!--start form-->
        <div class="w-full">

            <form [formGroup]="qualificationForm" (ngSubmit)="updateQualification()">

                <!-- <mat-form-field class="w-full">
                    <mat-label class="text-[14px] font-regular text-grey">College Name</mat-label>
                    <input id="" matInput formControlName="college_name" placeholder="Enter your College Name">
                    <mat-error class="absolute">
                       This field is required
                    </mat-error>
                </mat-form-field> -->
                <mat-form-field class="w-full" appearance="fill">
                    <mat-label class="text-[14px] text-accent-400 font-normal asterix-after">College
                        Name</mat-label>
                    <!-- <img matPrefix width="20" height="20" class="w-8"
                        [src]="selectedCollegeLogo? selectedCollegeLogo:'assets/icons/college-placeholder-img.png'"> -->
                    <input type="text" matInput [formControlName]="'college_name'" [matAutocomplete]="auto"
                        placeholder="Search your College" (keyup)="onSearchCollege($event)">
                    <mat-error class=""
                        *ngIf="qualificationForm.get('college_name').hasError('required')">
                        College field is required
                    </mat-error>
                    <mat-autocomplete #auto="matAutocomplete" [displayWith]="displayFn"
                        (optionSelected)="onSelectCollege($event)">
                        <mat-option *ngFor="let category of filteredOptions| async" [value]="category"
                            class="flex gap-4 items-center h-16">
                            <!-- {{category.viewValue}} -->
                            
                            <!-- <img class="w-10 h-10 clg-logo" [src]="category.college_icon" #foo> -->
                            <div class="flex flex-col">
                                <span
                                    class="pl-4 leading-5 text-lg text-accent font-medium">{{category.college}}</span>
                                <span
                                    class="flex pl-4 block leading-5 text-accent-200 text-base">{{category.district}}</span>
                            </div>
                        </mat-option>
                    </mat-autocomplete>
                </mat-form-field>
                <mat-form-field [ngClass]="" class="w-full ">
                    <mat-label class="text-[14px] font-regular text-grey">Department</mat-label>
                    <input matInput [formControlName]="'department'" onkeydown="return /[a-z ]/i.test(event.key)"  placeholder="Enter your study"
                        class="pl-4 br-phn w-full">
                    <!-- <mat-error class=""
                        *ngIf="qualificationFormControls.get('department').touched && qualificationFormControls.get('department').hasError('required')">
                        Department field is required
                    </mat-error> -->
                    <mat-select [value]="selectedDegree" matPrefix (selectionChange)="onChangeDegree($event)">
                        <mat-option *ngFor="let degree of degrees" [value]="degree._id">{{degree.name}}</mat-option>
                    </mat-select>
                    <mat-error class=""
                        *ngIf="qualificationForm.get('department').hasError('required')">
                        Department field is required
                    </mat-error>
                </mat-form-field>

                <div class="w-full flex flex-wrap justify-between">
                    <div class="w-full md:w-1/2 flex sm:pr-0 md:pr-2">
                        <mat-form-field [ngClass]="" class="w-6/12 pl-2 md:pl-2">
                            <mat-label class="text-[14px] font-regular text-grey">Start Year</mat-label>
                            <mat-select [value]="selectedStartYear" (selectionChange)="onChangeStartYear($event)">
                                <mat-option *ngFor="let year of startYear"
                                    [value]="year.value">{{year.year}}</mat-option>
                            </mat-select>
                        </mat-form-field>
                    </div>
                    <div class="w-full md:w-1/2 flex  sm:pl-0 md:pl-2">
                        <mat-form-field [ngClass]="" class="w-6/12 pl-2 md:pl-2">
                            <mat-label class="text-[14px] font-regular text-grey">Graduation year</mat-label>
                            <mat-select [value]="selectedEndYear" (selectionChange)="onChangeEndYear($event)">
                                <mat-option *ngFor="let year of endYear" [value]="year.value">{{year.year}}</mat-option>
                            </mat-select>
                        </mat-form-field>
                    </div>
                </div>

                <div class="my-2 w-full flex flex-wrap items-center justify-between">
                    <div><button type="button" class="text-white bg-primary hover:bg-accent border border-solid border-primary hover:border-accent focus:outline-none font-regular rounded-lg text-sm sm:text-lg font-medium py-2 px-6 sm:py-2.5 sm:px-8 text-center box-shadow-custom" (click)="reset()">Reset</button></div>
                    <div>
                        <div class="my-4 text-center">
                            <button type="submit" [ngClass]="{'logo-color-bg-disabled px-6 ml-3' :  qualificationForm.invalid}" [disabled]="qualificationForm.get('college_name').hasError('required') || qualificationForm.get('department').hasError('required')" *ngIf="!processing"
                                class="text-white bg-primary hover:bg-accent border border-solid border-primary hover:border-accent focus:outline-none font-regular rounded-lg text-sm sm:text-lg font-medium py-2 px-6 sm:py-2.5 sm:px-8 text-center box-shadow-custom">Update</button>
                            <button type="button" *ngIf="processing"
                                class="text-white cursor-not-allowed bg-primary hover:bg-accent border border-solid border-primary hover:border-accent focus:outline-none font-regular rounded-lg text-sm sm:text-lg font-medium py-2 px-6 sm:py-2.5 sm:px-8 text-center box-shadow-custom">Processing...</button>
                        </div>
                    </div>
                </div>
            </form>
        </div>
        <!--end---->
    </div>
</app-common-modal>
