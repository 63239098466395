/* eslint-disable @typescript-eslint/ban-types */
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
    providedIn: 'root'
})
/**
 * Common Service
 */
export class CommonService {
    // user id
    public userId: any;
    public useIdObserve: Observable<any>;

    // profile image
    public profileImage: any;
    public profileImageObserve: Observable<any>;

    // otp -created time transfer
    public otpCreated: any;
    public otpCreatedObserve: Observable<any>;

    // event id transfer
    public eventId: any;
    public eventIdObserve: Observable<any>;

    // registered student details transfer
    public regStudentDetails: any;
    public regStudentDetailObs: Observable<any>;

    // profile image uploaded- notification
    public profileImageUpload: any;
    public profileImageUploadObs: Observable<any>;

    // profile image to be edited- notification
    public profileImageToEdit: any;
    public profileImageToEditObs: Observable<any>;

    // certificae recreated
    public certificateRecreate: any;
    public certificateRecreateObs: Observable<any>;

    /**
     * Constructor
     */
    constructor(

    ) {
        // user id
        this.userId = new BehaviorSubject('');
        this.useIdObserve = this.userId.asObservable();

        // profile image
        this.profileImage = new BehaviorSubject('');
        this.profileImageObserve = this.profileImage.asObservable();

        // otp -created time
        this.otpCreated = new BehaviorSubject('');
        this.otpCreatedObserve = this.otpCreated.asObservable();

        // event id
        this.eventId = new BehaviorSubject('');
        this.eventIdObserve = this.eventId.asObservable();

        // registered student details-nitification
        this.regStudentDetails = new BehaviorSubject('');
        this.regStudentDetailObs = this.regStudentDetails.asObservable();

        // profile image uploaded- notification
        this.profileImageUpload = new BehaviorSubject('');
        this.profileImageUploadObs = this.profileImageUpload.asObservable();

        // profile image to be edited- notification
        this.certificateRecreate = new BehaviorSubject('');
        this.certificateRecreateObs = this.certificateRecreate.asObservable();
    }

    /**
     * get and pass user id
     *
     * @param data
     */
    public getUserId(data: string): void {
        if (data) {
            this.userId.next(data);
        }
    }


    /**
     * profile image url
     *
     * @param data
     */
    public getProfileImage(data: string): void {
        if (data) {
            this.profileImage.next(data);
        }
    }

    /**
     * otp-created time observe
     *
     * @param data
     */
    public otpCreatedTimeObserve(data: string): void {
        if (data) {
            this.otpCreated.next(data);
        }
    }

    /**
     * event Id
     *
     * @param data
     */
    public eventIdObservable(data: string): void {
        if (data) {
            this.eventId.next(data);
        }
    }

    /**
     * registered event
     *
     * @param data
     */
    public regStudentObservable(data: string): void {
        if (data) {
            this.regStudentDetails.next(data);
        }
    }

    /**
     * uploaded profile image url
     *
     * @param data
     */
    public getUploadedProfileImage(data: any): void {
        if (data) {
            this.profileImageUpload.next(data);
        }
    }

    /**
     * profile image to be edited
     *
     * @param data
     */
    public getImageUrlToEdit(data: string): void {
        if (data) {
            this.profileImageToEdit?.next(data);
        }
    }

    /**
     * certificate recreate info
     *
     * @param data
     */
    public certificateRecreateInfo(data: any): void {
        if (data?.updated) {
            this.certificateRecreate.next(data);
        }
    }



}
