import { NgModule } from '@angular/core';
import { CommonModule, DatePipe } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { TranslocoModule } from '@ngneat/transloco';
import { NgApexchartsModule } from 'ng-apexcharts';
import { MatOptionModule, MatRippleModule } from '@angular/material/core';
import { MatMenuModule } from '@angular/material/menu';
import { MatIconModule } from '@angular/material/icon';
import { MatDividerModule } from '@angular/material/divider';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatSortModule } from '@angular/material/sort';
import { MatTableModule } from '@angular/material/table';
import { MatTabsModule } from '@angular/material/tabs';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatRadioModule } from '@angular/material/radio';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatPaginatorModule } from '@angular/material/paginator';
import { ResendOtpPopupComponent } from './resend-otp-popup/resend-otp-popup.component';
import { MatDialogModule } from '@angular/material/dialog';
import { MatChipsModule } from '@angular/material/chips';
import { ImageViewPopupComponent } from './image-view-popup/image-view-popup.component';
import { CommonModalComponent } from './common-modal/common-modal.component';
import { QrcodeViewComponent } from './qrcode-view/qrcode-view.component';
import { ClipboardModule } from '@angular/cdk/clipboard';
import { MatStepperModule } from '@angular/material/stepper';
import { QRCodeModule } from 'angularx-qrcode';
import { MatSelectModule } from '@angular/material/select';
import { MatListModule } from '@angular/material/list';

import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { AddSummaryPopupComponent } from './add-summary-popup/add-summary-popup.component';
import { AddQualifiationComponent } from './add-qualifiation/add-qualifiation.component';
import { EditProfileComponent } from './edit-profile/edit-profile.component';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatCalendar } from '@angular/material/datepicker';
import { FooterComponent } from './footer/footer.component';
import { AddCertificateComponent } from './add-certificate/add-certificate.component';
import { ProfileUploadComponent } from './profile-upload/profile-upload.component';
import { ConfirmatioModalComponent } from './confirmation - modal/confirmation-modal.component';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { QuillModule } from 'ngx-quill';

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        MatButtonModule,
        MatDividerModule,
        MatIconModule,
        MatMenuModule,
        MatProgressBarModule,
        MatRippleModule,
        MatSidenavModule,
        MatSortModule,
        MatTableModule,
        MatTabsModule,
        NgApexchartsModule,
        TranslocoModule,
        MatButtonToggleModule,
        MatRadioModule,
        MatFormFieldModule,
        MatInputModule,
        MatToolbarModule,
        MatPaginatorModule,
        MatDialogModule,
        MatChipsModule,
        ClipboardModule,
        MatStepperModule,
        QRCodeModule,
        MatSelectModule,
        MatOptionModule,
        MatProgressSpinnerModule,
        MatListModule,
        MatAutocompleteModule,
        MatSlideToggleModule,
        QuillModule.forRoot(),
    ],
    exports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        MatButtonModule,
        MatDividerModule,
        MatIconModule,
        MatMenuModule,
        MatProgressBarModule,
        MatRippleModule,
        MatSidenavModule,
        MatSortModule,
        MatTableModule,
        MatTabsModule,
        NgApexchartsModule,
        TranslocoModule,
        MatButtonToggleModule,
        MatRadioModule,
        MatFormFieldModule,
        MatInputModule,
        MatToolbarModule,
        MatPaginatorModule,
        MatDialogModule,
        ResendOtpPopupComponent,
        MatChipsModule,
        ImageViewPopupComponent,
        CommonModalComponent,
        QrcodeViewComponent,
        MatStepperModule,
        ClipboardModule,
        MatSelectModule,
        MatProgressSpinnerModule,
        AddSummaryPopupComponent,
        AddQualifiationComponent,
        EditProfileComponent,
        FooterComponent,
        AddCertificateComponent,
        ProfileUploadComponent,
        ConfirmatioModalComponent,
        MatSlideToggleModule,
    ],
    declarations: [
        ResendOtpPopupComponent,
        ImageViewPopupComponent,
        CommonModalComponent,
        QrcodeViewComponent,
        AddSummaryPopupComponent,
        AddQualifiationComponent,
        EditProfileComponent,
        FooterComponent,
        AddCertificateComponent,
        ProfileUploadComponent,
        ConfirmatioModalComponent,
    ]
})
export class SharedModule {
}
