/* eslint-disable @typescript-eslint/explicit-function-return-type */
import { Route } from '@angular/router';
import { AuthGuard } from 'app/core/auth/guards/auth.guard';
import { NoAuthGuard } from 'app/core/auth/guards/noAuth.guard';
import { LayoutComponent } from 'app/layout/layout.component';
import { InitialDataResolver } from 'app/app.resolvers';
import { AdminAuthGuard } from './modules/auth-guards/admin-auth';


// @formatter:off
export const appRoutes: Route[] = [

    // Redirect empty path to '/example'
    // { path: '', pathMatch: 'full', redirectTo: '/dashboard' },
    { path: '', pathMatch: 'full', redirectTo: '/user-sign-in' },

    // Redirect signed in user to the '/example'
    //
    // After the user signs in, the sign in page will redirect the user to the 'signed-in-redirect'
    // path. Below is another redirection for that path to redirect the user to the desired
    // location. This is a small convenience to keep all main routes together here on this file.
    { path: 'signed-in-redirect', pathMatch: 'full', redirectTo: '/confirm-sign-in' },

    // Auth routes for guests
    {
        path: '',
        canActivate: [NoAuthGuard],
        canActivateChild: [NoAuthGuard],
        component: LayoutComponent,
        data: {
            layout: 'empty'
        },
        children: [
            {
                path: 'confirmation-required', loadChildren: () => import('app/modules/auth/confirmation-required/confirmation-required.module').
                    then(m => m.AuthConfirmationRequiredModule)
            },
            { path: 'forgot-password', loadChildren: () => import('app/modules/auth/forgot-password/forgot-password.module').then(m => m.AuthForgotPasswordModule) },
            { path: 'reset-password', loadChildren: () => import('app/modules/auth/reset-password/reset-password.module').then(m => m.AuthResetPasswordModule) },
            { path: 'sign-in', loadChildren: () => import('app/modules/auth/sign-in/sign-in.module').then(m => m.AuthSignInModule) },
            { path: 'sign-up', loadChildren: () => import('app/modules/auth/sign-up/sign-up.module').then(m => m.AuthSignUpModule) },
            { path: 'confirm-sign-in', loadChildren: () => import('app/modules/auth/confirm-sign-in/confirm-sign-in.module').then(m => m.ConfirmSignInModule) },
        ]
    },
    // Auth routes for guests
    {
        path: '',
        canActivate: [NoAuthGuard],
        canActivateChild: [NoAuthGuard],
        component: LayoutComponent,
        data: {
            layout: 'empty'
        },
        children: [
            {
                path: 'user-confirmation-required', loadChildren: () => import('app/modules/user-auth/confirmation-required/confirmation-required.module').
                    then(m => m.UserAuthConfirmationRequiredModule)
            },
            {
                path: 'user-forgot-password', loadChildren: () => import('app/modules/user-auth/forgot-password/forgot-password.module').
                    then(m => m.UserAuthForgotPasswordModule)
            },
            { path: 'user-reset-password', loadChildren: () => import('app/modules/user-auth/reset-password/reset-password.module').then(m => m.UserAuthResetPasswordModule) },
            { path: 'user-sign-in', loadChildren: () => import('app/modules/user-auth/sign-in/sign-in.module').then(m => m.UserAuthSignInModule) },
            { path: 'user-sign-up', loadChildren: () => import('app/modules/user-auth/sign-up/sign-up.module').then(m => m.UserAuthSignUpModule) },
            { path: 'user-otp-verification', loadChildren: () => import('app/modules/user-auth/otp-verification/otp-verification.module').then(m => m.UserOtpVerificationModule) },
            { path: 'user-sign-out', loadChildren: () => import('app/modules/user-auth/sign-out/sign-out.module').then(m => m.UserAuthSignOutModule) },
            { path: 'user-mail-verification', loadChildren: () => import('app/modules/user-auth/mail-verification/mail-verification.module').then(m => m.MailVerificationModule) },
        ]
    },

    // Auth routes for authenticated users
    {
        path: '',
        component: LayoutComponent,
        data: {
            layout: 'empty'
        },
        children: [
            { path: 'sign-out', loadChildren: () => import('app/modules/auth/sign-out/sign-out.module').then(m => m.AuthSignOutModule) },
            { path: 'unlock-session', loadChildren: () => import('app/modules/auth/unlock-session/unlock-session.module').then(m => m.AuthUnlockSessionModule) }
        ]
    },

    // Landing routes
    {
        path: '',
        component: LayoutComponent,
        data: {
            layout: 'empty'
        },
        children: [
            { path: 'home', loadChildren: () => import('app/modules/landing/home/home.module').then(m => m.LandingHomeModule) },
        ]
    },

    // Admin routes
    {
        path: '',
        canActivate: [AuthGuard],
        canActivateChild: [AuthGuard],
        component: LayoutComponent,
        resolve: {
            initialData: InitialDataResolver,

            children: [
                { path: 'example', loadChildren: () => import('app/modules/admin/example/example.module').then(m => m.ExampleModule) },
            ]
        },
    },

    // Event management
    {
        path: '',
        resolve: {
            initialData: InitialDataResolver,
        },
        data: {
            layout: 'classy'
        },
        component: LayoutComponent,
        children: [
            // eslint-disable-next-line max-len
            { canActivate: [AdminAuthGuard], path: '', loadChildren: () => import('app/modules/admin/event-management/event-management.module').then(m => m.EventManagementModule) },
            { canActivate: [AdminAuthGuard], path: 'dashboard', loadChildren: () => import('app/modules/admin/dashboard/dashboard.module').then(m => m.DashBoardModule) },
            { canActivate: [AdminAuthGuard], path: '', loadChildren: () => import('app/modules/admin/user-management/user-management.module').then(m => m.UserManagementModule) },
            { canActivate: [AdminAuthGuard], path: '', loadChildren: () => import('app/modules/admin/speaker-management/speaker-management.module').then(m => m.SpeakerManagementModule) },
        ]
    },

    // User layout
    {
        path: '',
        resolve: {
            initialData: InitialDataResolver,
        },
        data: {
            layout: 'enterprise'
        },

        component: LayoutComponent,
        children: [
            // { path: 'event-registration', loadChildren: () => import('app/modules/user/event/event.module').then(m => m.EventModule) },
            {
                path: 'events',
                loadChildren: () => import('app/modules/user/events/events.module').then(m => m.EventsModule)
            },
            {
                path: 'your-events',
                loadChildren: () => import('app/modules/user/your-events/your-events.module').then(m => m.YourEventsModule)
            },
            {
                path: 'user-dashboard/:id',
                loadChildren: () => import('app/modules/user/dashboard/dashboard.module').then(m => m.UserDashboardModule)
            },
            {
                path: 'user-profile',
                loadChildren: () => import('app/modules/user/student-profile/student-profile.module').then(m => m.StudentProfileModule)
            },
            {
                path: 'user-attendance-scanner',
                loadChildren: () => import('app/modules/user/attendance-scanner/attendance-scanner.module').then(m => m.AttendanceScannerModule)
            },
        ]
    },
    // Error page
    {
        path: '',
        component: LayoutComponent,
        data: {
            layout: 'empty'
        },
        children: [
            { path: '**', loadChildren: () => import('app/modules/page-not-found/error-page/error-page.module').then(m => m.Error404Module) },
        ]
    },


];
