/* eslint-disable @typescript-eslint/naming-convention */
/* eslint-disable @typescript-eslint/member-ordering */
/* eslint-disable @typescript-eslint/explicit-function-return-type */
import { ChangeDetectorRef, Component, Input, OnDestroy, OnInit } from '@angular/core';
import { CommonService } from 'app/modules/services/common.service';
import { FileUploadService } from 'app/modules/services/file-upload.service';
import { ModalDataService } from 'app/modules/services/modal-data.service';
import { ModalService } from 'app/modules/services/modal.service';
import { ProfileService } from 'app/modules/user/student-profile/services/profile.service';
import { ToastrService } from 'ngx-toastr';

/**
 * Component
 */
@Component({
  selector: 'app-profile-upload',
  templateUrl: './profile-upload.component.html',
  styleUrls: ['./profile-upload.component.scss']
})

/**
 * Component
 */
export class ProfileUploadComponent implements OnInit, OnDestroy {
  public selectedFile: any;
  public selectedImage: any;
  public preview: any;
  public imageToEdit: string;
  public isFileUploaded: boolean = false;
  @Input() studentId: string = '';
  public pageHeader: string = 'Profile image upload';

  /**
   * constructor
   */
  constructor(
    private modalService: ModalService,
    private fileUploadService: FileUploadService,
    private toastr: ToastrService,
    private commonService: CommonService,
    private profileService: ProfileService,
    private cdr: ChangeDetectorRef,
  ) { }

  /**
   * initially loaded
   */
  ngOnInit(): void {
    this.commonService.profileImageToEditObs.subscribe(((res) => {
      this.imageToEdit = res;
      this.selectedImage = '';
    }));
  }

  /*
   * close modal
   */
  public closeModel(): void {
    this.pageRefreshing();
    this.modalService.close('upload-profile-image');
  }


  /**
   *
   * @param data select image
   */
  public onSelectImage(data): void {
    this.selectedFile = data.target.files;
    if (this.selectedFile.length > 0) {
      if (this.selectedFile && this.selectedFile[0]) {
        const numberOfFiles = this.selectedFile.length;
        for (let i = 0; i < numberOfFiles; i++) {
          const reader = new FileReader();
          reader.onload = (e: any) => {
            this.preview = e?.target?.result;
            if (this.imageToEdit) {
              this.onSaveImage();
            } else {
              this.selectedImage = e?.target?.result;
            }
          };
          reader.readAsDataURL(this.selectedFile[i]);
        }
      }
    }
  }

  /**
   * remove image
   */
  public removeSelectedImage() {
    this.selectedImage = '';

  }

  /**
   * save image
   */
  public onSaveImage() {
    this.fileUploadService.uploadFile(this.selectedFile[0], this.selectedFile.length).subscribe(
      (data: any) => {
        console.log(data);
        if (data?.status) {
          // eslint-disable-next-line @typescript-eslint/naming-convention
          const param = { 'profile_picture': data?.body?.data };
          this.profileService.updateProfile(this.studentId, param).subscribe(((res) => {
            if (res?.status) {
              this.pageHeader = 'Image uploaded Successfully';
              if (this.imageToEdit) {
                this.imageToEdit = res?.data?.profile_picture;
              } else {
                this.selectedImage = res?.data?.profile_picture;
                this.isFileUploaded = true;
              }
              this.commonService.getUploadedProfileImage({ image: res?.data?.profile_picture });

            }
          }));
        }
      },
      (err: any) => {
        this.toastr.error('Somethind went wrong');
      },
    );
  }

  /**
   * cancel uploading
   */
  public onCancelUpload() {
    this.pageRefreshing();
    this.modalService.close('upload-profile-image');
  }

  /**
   * remove profile image
   */
  public onRemoveProfileImage() {
    this.fileUploadService.deleteFile(this.imageToEdit).subscribe(((data) => {
      if (data?.status) {
        const param = { 'profile_picture': '' };
        this.imageToEdit = '';
        this.profileService.updateProfile(this.studentId, param).subscribe((res) => {
          if (res?.status) {
            this.commonService.getUploadedProfileImage({ image: '' });
            this.pageHeader = 'Profile image upload';
            this.modalService.close('upload-profile-image');
          }
        });
      }
    }));
  }

  /**
   * page refresh
   */
  public pageRefreshing() {
    this.imageToEdit = '';
    this.selectedImage = '';
    this.isFileUploaded = false;
    this.pageHeader = 'Profile image upload';
  }

  /**
   * when page closing
   */
  ngOnDestroy(): void {
    // this.imageToEdit = '';
    // this.selectedImage = '';
    // this.isFileUploaded = false;
    // this.pageHeader = '';
  }
}
