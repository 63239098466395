/* eslint-disable @typescript-eslint/explicit-function-return-type */
/* eslint-disable @typescript-eslint/member-ordering */
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class DataProviderService {
    private dataProvider = new BehaviorSubject(null);
    public dataListener: Observable<any> = this.dataProvider.asObservable();

  constructor() { }

  /**
   * Datas emit
   *
   * @param data
   */
  public dataEmit(data: any) {
    this.dataProvider.next(data);
  }
}
