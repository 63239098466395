<!-- show QR code -->


<app-common-modal id="qr-code-modal" class="app-common-modal" [heightClass]="'h-2/4'" [widthClass]="'md:w-3/6 mx-auto'">
    <loading *ngIf="isLoading">{{ isLoading }}</loading>
    <div class="relative" *ngIf="qrSrc">
        <img src="assets/images/close.svg"
            class="absolute right-2 w-8 -mr-6 -mt-4 shadow-md shadow-red-300 rounded-full cursor-pointer" alt=""
            (click)="closeModel()">

        <img [src]="qrSrc" alt="" id="qr-image" class="cursor-pointer">
        <!-- <h3>angularx-qrcode demo implementation</h3>
        <qrcode id="qr-img" #parent [qrdata]="qrSrc" [size]="256" [level]="'M'"></qrcode>
        <br>
        <button (click)="saveAsImage(parent)">Download QR Code Image</button> -->
        <!-- <app-image-component [image]="imagrSrc" [class]="'w-full h-full '">
      </app-image-component>-->
    </div>
    <div class="flex gap-1 justify-end my-2">
        <button class="apply-codespot size-16 p-2" (click)="onDownloadQrImage(qrSrc)">Download</button>
        <button class="apply-codespot size-16 p-2" (click)="onCopyQrImage(qrSrc)">Copy Image</button>
    </div>
</app-common-modal>