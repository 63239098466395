/* eslint-disable @typescript-eslint/naming-convention */
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from 'environments/environment';

@Injectable({
    providedIn: 'root'
})
/**
 * Event services
 */
export class CreateEventService {
    public baseUrl = environment.API_BASE_URL;

    /**
     * Constructor
     */
    constructor(
        private _httpClient: HttpClient,

    ) {

    }


    /**
     * create event
     *
     * @param data
     * @return
     */
    createEvent(data): Observable<any> {
        console.log(data);
        return this._httpClient.post(this.baseUrl + 'admin/event', data, { headers: { 'Cache-Control': 'no-cache, no-store, must-revalidate' } });
    }

    /**
     * get All evant
     *
     * @param data
     * @return
     */
    getAllEvent(data,limit): Observable<any> {
        return this._httpClient.get(this.baseUrl + `admin/events?page=${data}&limit=${limit}`);
    }

    /**
     * get event by id
     *
     * @param data
     * @return
     */
    getEventById(data): Observable<any> {
        return this._httpClient.get(this.baseUrl + `admin/event?id=${data}`);
    }

    /**
     * update event
     *
     * @param id
     * @param data
     * @return
     */
    updateEvent(data, id): Observable<any> {
        return this._httpClient.patch(this.baseUrl + `admin/event?id=${id}`, data);
    }

    /**
     * delete event
     *
     * @param data
     * @return
     */
    deleteEvent(data: string): Observable<any> {
        return this._httpClient.delete(this.baseUrl + `admin/event?id=${data}`);
    }


}
