<div class="w-62">
    <div>
        <h1 mat-dialog-title>OTP verification</h1>
    </div>
    <div mat-dialog-content>
        <p>You should verify otp before sign in.</p>
    </div>
    <div mat-dialog-actions>
        <button class="bg-primary text-white" mat-button (click)="redirectToOtpVerification()">Ok</button>
        <button mat-button mat-dialog-close>Close</button>
    </div>
</div>