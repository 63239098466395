import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit {
  public currentYear: any;
  constructor() { }

  ngOnInit(): void {
    const copyright = new Date();
    this.currentYear = copyright.getFullYear();
  }
/**
       * Scrolls to top
       */
scrollToTop() {
  window.scrollTo(0, 0);
}
}
