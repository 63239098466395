import { Injectable } from '@angular/core';
import { CanActivate, Router } from '@angular/router';
import { LocalStorageService } from 'app/modules/services/local-storage.service';
import { SessionStorageService } from 'app/modules/services/session-storage.service';

@Injectable()
export class LoggedInAuthGuard implements CanActivate {

    constructor(
        private localStorageService: LocalStorageService,
        private _router: Router,
        private sessioStorageService: SessionStorageService,
        ) { }

    canActivate(): boolean {
        if (this.localStorageService.getDataByKey('student') ? this.localStorageService.getDataByKey('student') : this.sessioStorageService.getDataByKey('student')) {
            this._router.navigate(['/events']);
            return false;
        } else {
            return true;
        }
    }
}
