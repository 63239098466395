import { Component, OnInit } from '@angular/core';
import { ModalDataService } from 'app/modules/services/modal-data.service';
import { ModalService } from 'app/modules/services/modal.service';
@Component({
  selector: 'app-add-certificate',
  templateUrl: './add-certificate.component.html',
  styleUrls: ['./add-certificate.component.scss']
})
export class AddCertificateComponent implements OnInit {

  constructor( private modalService: ModalService, private modalDataService: ModalDataService) { }

  ngOnInit(): void {
  }
  public closeModel(): void {
    this.modalService.close('add-certificate');
  }
}
