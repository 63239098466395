import { Component, Input, OnInit } from '@angular/core';
import { ModalDataService } from 'app/modules/services/modal-data.service';
import { ModalService } from 'app/modules/services/modal.service';
@Component({
  selector: 'app-image-view-popup',
  templateUrl: './image-view-popup.component.html',
  styleUrls: ['./image-view-popup.component.css'],
})


/**
 * Image popup view
 */
export class ImageViewPopupComponent implements OnInit {
  public imagrSrc: string;
  public fileGrouping: any;
  isLoading = false;
  // @Input() secondModalOpen:boolean;
  /**
   * @constructor
   */
  constructor(
    private modalService: ModalService,
    private modalDataService: ModalDataService,
  ) { }

  /**
   * called initially
   */
  ngOnInit(): void {
    // this.fileGrouping = FILE_GROUPING;
    this.modalDataService.setImageViewPopupObserve.subscribe((imageSrc: string) => {
      if (!imageSrc) { return; }
      this.imagrSrc = imageSrc;
      console.log(this.imagrSrc);
    });
  }

  /**
   * Close modal
   */
  public closeModel(): void {
    this.modalService.close('view-image-modal');
    // if (this.secondModalOpen) {
    //   this.modalService.open('open-search-modal');
    // }
  }

  /**
   * Get file format
   *
   * @param type
   * @return
   */
  public checkFileType(type: string): boolean {
    return this.imagrSrc ? this.fileGrouping[type].indexOf(this.imagrSrc?.split('.')[this.imagrSrc?.split('.').length - 1]) !== -1 : false;
  }
  /**
   * file loaDER
   */
  viewImage(): void {
    this.isLoading = true;
    setTimeout(() => this.isLoading = false, 2000);
  }
}
