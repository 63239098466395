<!-- Button -->
<button mat-icon-button [matMenuTriggerFor]="userActions">
    <span class="relative">
        <img class="w-7 h-7 rounded-full" [src]="profileImage ? profileImage : 'assets/images/avatars/demo-1.png'">
        <mat-icon *ngIf="!showAvatar || !user.avatar" [svgIcon]="'heroicons_outline:user-circle'"></mat-icon>
        <span class="absolute right-0 bottom-0 w-2 h-2 rounded-full" [ngClass]="{'mr-px mb-px': !showAvatar || !user.avatar,
                          'bg-green-500': user.status === 'online',
                          'bg-amber-500': user.status === 'away',
                          'bg-red-500': user.status === 'busy',
                          'bg-gray-400': user.status === 'not-visible'}"></span>
    </span>
</button>

<mat-menu [xPosition]="'before'" #userActions="matMenu">
    <button mat-menu-item>
        <span class="flex flex-col leading-none">
            <span>Signed in as</span>
            <span *ngIf="studentDetail?.username" class="mt-1.5 text-md font-medium">{{studentDetail?.username}}</span>
            <span *ngIf="!studentDetail?.username" class="mt-1.5 text-md font-medium">User</span>
        </span>
    </button>
    <mat-divider class="my-2"></mat-divider>
    <button mat-menu-item (click)="profilePageNavigation()">
        <mat-icon [svgIcon]="'heroicons_outline:user-circle'"></mat-icon>
        <span>Profile</span>
    </button>
    <!-- <button mat-menu-item>
        <mat-icon [svgIcon]="'heroicons_outline:cog'"></mat-icon>
        <span>Settings</span>
    </button>
    <button mat-menu-item [matMenuTriggerFor]="userStatus">
        <mat-icon [svgIcon]="'heroicons_outline:dots-circle-horizontal'"></mat-icon>
        <span>Status</span>
    </button> -->
    <mat-divider class="my-2"></mat-divider>
    <button mat-menu-item (click)="onUserSignOut()">
        <mat-icon [svgIcon]="'heroicons_outline:logout'"></mat-icon>
        <span>Sign out</span>
    </button>
</mat-menu>

<mat-menu class="user-status-menu" #userStatus="matMenu">
    <button mat-menu-item (click)="updateUserStatus('online')">
        <span class="w-4 h-4 mr-3 rounded-full bg-green-500"></span>
        <span>Online</span>
    </button>
    <button mat-menu-item (click)="updateUserStatus('away')">
        <span class="w-4 h-4 mr-3 rounded-full bg-amber-500"></span>
        <span>Away</span>
    </button>
    <button mat-menu-item (click)="updateUserStatus('busy')">
        <span class="w-4 h-4 mr-3 rounded-full bg-red-500"></span>
        <span>Busy</span>
    </button>
    <button mat-menu-item (click)="updateUserStatus('not-visible')">
        <span class="w-4 h-4 mr-3 rounded-full bg-gray-400"></span>
        <span>Invisible</span>
    </button>
</mat-menu>

<!-- Messages panel -->
<!-- <ng-template #messagesPanel>

    <div
        class="fixed inset-0 sm:static sm:inset-auto flex flex-col sm:min-w-90 sm:w-90 overflow-hidden shadow-lg"> -->

<!-- Header -->
<!-- <div class="flex flex-shrink-0 items-center py-4 pr-4 pl-6 bg-primary text-on-primary">
            <div class="sm:hidden -ml-1 mr-3">
                <button mat-icon-button (click)="closePanel()">
                    <mat-icon class="icon-size-5 text-current" [svgIcon]="'heroicons_solid:x'"></mat-icon>
                </button>
            </div>
            <div class="text-lg font-medium leading-10">Messages</div>
            <div class="ml-auto">
                <button mat-icon-button [disabled]="unreadCount === 0" [matTooltip]="'Mark all as read'"
                    (click)="markAllAsRead()">
                    <mat-icon class="icon-size-5 text-current" [svgIcon]="'heroicons_solid:mail-open'"></mat-icon>
                </button>
            </div>
        </div> -->

<!-- Content -->
<!-- <div class="relative flex flex-col flex-auto sm:max-h-120 divide-y overflow-y-auto bg-card"> -->
<!-- Messages -->
<!-- <ng-container *ngFor="let message of messages; trackBy: trackByFn">
                <div class="flex group hover:bg-gray-50 dark:hover:bg-black dark:hover:bg-opacity-5"
                    [ngClass]="{'unread': !message.read}"> -->

<!-- Message with a link -->
<!-- <ng-container *ngIf="message.link"> -->
<!-- Normal links -->
<!-- <ng-container *ngIf="!message.useRouter">
                            <a class="flex flex-auto py-5 pl-6 cursor-pointer" [href]="message.link">
                                <ng-container *ngTemplateOutlet="messageContent"></ng-container>
                            </a>
                        </ng-container> -->
<!-- Router links -->
<!-- <ng-container *ngIf="message.useRouter">
                            <a class="flex flex-auto py-5 pl-6 cursor-pointer" [routerLink]="message.link">
                                <ng-container *ngTemplateOutlet="messageContent"></ng-container>
                            </a>
                        </ng-container>
                    </ng-container> -->

<!-- Message without a link -->
<!-- <ng-container *ngIf="!message.link">
                        <div class="flex flex-auto py-5 pl-6">
                            <ng-container *ngTemplateOutlet="messageContent"></ng-container>
                        </div>
                    </ng-container> -->

<!-- Actions -->
<!-- <div class="relative flex flex-col my-5 mr-6 ml-2"> -->
<!-- Indicator -->
<!-- <button class="w-6 h-6 min-h-6" mat-icon-button (click)="toggleRead(message)"
                            [matTooltip]="message.read ? 'Mark as unread' : 'Mark as read'">
                            <span class="w-2 h-2 rounded-full" [ngClass]="{'bg-gray-400 dark:bg-gray-500 sm:opacity-0 sm:group-hover:opacity-100': message.read,
                                            'bg-primary': !message.read}"></span>
                        </button> -->
<!-- Remove -->
<!-- <button class="w-6 h-6 min-h-6 sm:opacity-0 sm:group-hover:opacity-100" mat-icon-button
                            (click)="delete(message)" [matTooltip]="'Remove'">
                            <mat-icon class="icon-size-4" [svgIcon]="'heroicons_solid:x'"></mat-icon>
                        </button>
                    </div>
                </div> -->

<!-- Message content template -->
<!-- <ng-template #messageContent> -->
<!-- Icon -->
<!-- <ng-container *ngIf="message.icon && !message.image">
                        <div
                            class="flex flex-shrink-0 items-center justify-center w-8 h-8 mr-4 rounded-full bg-gray-100 dark:bg-gray-700">
                            <mat-icon class="icon-size-5" [svgIcon]="message.icon">
                            </mat-icon>
                        </div>
                    </ng-container> -->
<!-- Image -->
<!-- <ng-container *ngIf="message.image">
                        <img class="flex-shrink-0 w-8 h-8 mr-4 rounded-full overflow-hidden object-cover object-center"
                            [src]="message.image" [alt]="'Message image'">
                    </ng-container> -->
<!-- Title, description & time -->
<!-- <div class="flex flex-col flex-auto">
                        <ng-container *ngIf="message.title">
                            <div class="font-semibold line-clamp-1" [innerHTML]="message.title"></div>
                        </ng-container>
                        <ng-container *ngIf="message.description">
                            <div class="line-clamp-2" [innerHTML]="message.description"></div>
                        </ng-container>
                        <div class="mt-2 text-sm leading-none text-secondary">
                            {{message.time | date:'MMM dd, h:mm a'}}
                        </div>
                    </div>
                </ng-template>
            </ng-container> -->

<!-- No messages -->
<!-- <ng-container *ngIf="!messages || !messages.length">
                <div class="flex flex-col flex-auto items-center justify-center sm:justify-start py-12 px-8">
                    <div class="flex flex-0 items-center justify-center w-14 h-14 rounded-full bg-primary-100">
                        <mat-icon class="text-primary-500-700" [svgIcon]="'heroicons_outline:inbox'"></mat-icon>
                    </div>
                    <div class="mt-5 text-2xl font-semibold tracking-tight">No messages</div>
                    <div class="w-full max-w-60 mt-1 text-md text-center text-secondary">When you have messages, they
                        will appear here.</div>
                </div>
            </ng-container>
        </div>
    </div>
</ng-template> -->