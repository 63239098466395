import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ExtraOptions, PreloadAllModules, RouterModule } from '@angular/router';
import { MarkdownModule } from 'ngx-markdown';
import { FuseModule } from '@fuse';
import { FuseConfigModule } from '@fuse/services/config';
import { FuseMockApiModule } from '@fuse/lib/mock-api';
import { CoreModule } from 'app/core/core.module';
import { appConfig } from 'app/core/config/app.config';
import { mockApiServices } from 'app/mock-api';
import { LayoutModule } from 'app/layout/layout.module';
import { AppComponent } from 'app/app.component';
import { appRoutes } from 'app/app.routing';
import { UserDashboardResolver } from './modules/user/dashboard/dashboard.resolvers';
import { UserSignInService } from './modules/services/use-sign-in.service';
import { UserListingService } from './modules/services/user-listing.service';
import { ToastContainerModule, ToastrModule } from 'ngx-toastr';
import { NgxMatDatetimePickerModule } from '@angular-material-components/datetime-picker';
import { CommonService } from './modules/services/common.service';
import { ModalDataService } from './modules/services/modal-data.service';
import { ModalService } from './modules/services/modal.service';
import { AgmCoreModule } from '@agm/core';
import { environment } from 'environments/environment';
import { QuillModule } from 'ngx-quill';
import { NgxPaginationModule } from 'ngx-pagination';
import { UserAuthGuard } from './modules/user-auth/guards/auth.guard';
import { LoggedInAuthGuard } from './modules/user-auth/guards/logged-in-auth.guard';
import { EventResolverService } from './modules/resolvers/event-resolver.service';


const routerConfig: ExtraOptions = {
    preloadingStrategy: PreloadAllModules,
    scrollPositionRestoration: 'enabled'
};

@NgModule({
    declarations: [
        AppComponent,
        // EventAttendanceDirective,
    ],
    imports: [
        BrowserModule,
        BrowserAnimationsModule,
        RouterModule.forRoot(appRoutes, routerConfig),

        // Fuse, FuseConfig & FuseMockAPI
        FuseModule,
        FuseConfigModule.forRoot(appConfig),
        FuseMockApiModule.forRoot(mockApiServices),

        // Core module of your application
        CoreModule,

        // Layout module of your application
        LayoutModule,


        // 3rd party modules that require global configuration via forRoot
        MarkdownModule.forRoot({}),
        ToastrModule.forRoot({
            preventDuplicates: true,
            maxOpened: 1,
            positionClass: 'toast-top-right',
            timeOut: 4000,

        }),
        ToastContainerModule,
        NgxMatDatetimePickerModule,
        AgmCoreModule.forRoot({
            apiKey: environment.MAP_KEY,
            libraries: ['places']
        }),
        QuillModule.forRoot(),
        NgxPaginationModule,

    ],
    bootstrap: [
        AppComponent
    ],
    providers: [UserDashboardResolver,
        UserSignInService,
        UserListingService,
        CommonService,
        ModalDataService,
        ModalService,
        UserAuthGuard,
        LoggedInAuthGuard,
        EventResolverService,
    ],
})
export class AppModule {
}
