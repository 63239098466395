<app-common-modal id="confirmation-modal" class="app-common-modal" [heightClass]="'h-auto'"
    [widthClass]="'md:w-3/6 mx-auto'">
    <div class="relative">
        <div class="w-full">
            <div>
                <h3 class="text-orange text-xl md:text-2xl font-medium">Confirmation</h3>
            </div>
            <div> <img src="assets/images/close.svg"
                    class="absolute right-2 w-8 -mr-6 -mt-8 shadow-md shadow-red-300 rounded-full cursor-pointer" alt=""
                    (click)="closeModel()"></div>
        </div>
        <hr>
        <!--start-->
        <div class="w-full">
            <div class="mb-8">
                <p>Are you want to {{message}} <b class="capitalize">{{Title}}</b> {{Type}} ?</p>
            </div>
            <div class="flex items-center justify-between">
                <button (click)="closeModel()" type="button"
                    class="text-red-500 border border-red-300 font-regular rounded-lg text-sm lg:text-base flex items-center hover:bg-red-500 hover:text-white h-8 sm:h-auto p-1 sm:p-2 px-4 md:py-2 md:px-6 text-center">Cancel</button>
                <button (click)="onConfirmation()" type="button"
                    class="text-white bg-primary hover:bg-accent border border-solid border-primary hover:border-accent focus:outline-none  rounded-lg text-sm lg:text-base font-normal  items-center py-2 px-6  sm:px-8 text-center">Ok</button>
            </div>
        </div>
        <!--end---->
    </div>
    <!--end-->
</app-common-modal>