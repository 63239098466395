/* eslint-disable no-debugger */
/* eslint-disable @typescript-eslint/explicit-function-return-type */
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { filter } from 'rxjs/internal/operators/filter';
import { map } from 'rxjs/internal/operators/map';
import { LocalStorageService } from './modules/services/local-storage.service';
import { SessionStorageService } from './modules/services/session-storage.service';

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
    public adminDetails: any;
    /**
     * Constructor
     */
    constructor(
        private router: Router,
        private activatedRoute: ActivatedRoute,
        private localStorageService: LocalStorageService,
        private sessionStorageService: SessionStorageService,
    ) {
    }
    ngOnInit(): void {
        this.adminDetails = this.localStorageService.getUserData() ? this.localStorageService.getUserData() : this.sessionStorageService.getUserData();
        this.redirectUrl(this.adminDetails);
    }

    /**
     * redirect function
     */
    redirectUrl(data): void {
        this.activatedRoute.queryParamMap.subscribe((res) => {
            console.log(res['id']);
        });
        // this.router.events
        //     .pipe(
        //         filter(event => event instanceof NavigationEnd),
        //         map((event: NavigationEnd) => event.url)
        //     )
        //     .subscribe((url) => {
        //        this.router.events.pipe((res: any)=>{
        //             console.log(res);
        //         });
        //         const lastUrlSegment = url.split('?')[0].split('/').pop();
        //         const adminModule = (lastUrlSegment === '/sign-in') || (lastUrlSegment === undefined);
        //         const userModule = (lastUrlSegment !== '/sign-in');
        //         // if ( ) {
        //         //     console.log(lastUrlSegment)
        //         // } elseif(true) {
        //         //     console.log(lastUrlSegment)
        //         // }
        //         // if (data?.role === 2) {
        //         //     this.router.navigateByUrl('/dashboard');
        //         // } else {
        //         //     this.router.navigateByUrl('/sign-in');
        //         // }
        //     });

    }
}
