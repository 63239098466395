/* eslint-disable @typescript-eslint/naming-convention */
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { environment } from 'environments/environment';
import { switchMap } from 'rxjs/operators';
import { LocalStorageService } from './local-storage.service';

@Injectable({
    providedIn: 'root'
})
/**
 * user signin module
 */
export class UserSignInService {
    public baseUrl = environment.API_BASE_URL;

    /**
     * Constructor
     */
    constructor(
        private _httpClient: HttpClient,
        private localStorageSevice: LocalStorageService,

    ) {

    }
    /**
     * Sign up
     *
     * @param data
     */
    userSignUp(data): Observable<any> {
        return this._httpClient.post(this.baseUrl + 'student/auth/signup', data, { headers: { 'Cache-Control': 'no-cache, no-store, must-revalidate' } });
    }

    /**
     * user name validation
     *
     * @param data
     */
    userNameValidation(data): Observable<any> {
        console.log(data);
        return this._httpClient.get(this.baseUrl + `student/check-username?username=${data}`);
    }

    /**
     * user emailvalidation
     *
     * @param data
     */
    userEmailValidation(data): Observable<any> {
        console.log(data);
        return this._httpClient.get(this.baseUrl + `student/check-email?email=${data}`);
    }

    /**
     * otp verification
     *
     * @param data
     */
    userOtpVerification(data): Observable<any> {
        const studentVerificationId = this.localStorageSevice.getDataByKey('student-Verification-id');
        if (studentVerificationId) {
            console.log('data.verification_code', data.verification_code);

            const otp = +data.verification_code;


            return this._httpClient.post(this.baseUrl + `student/verify-otp/${studentVerificationId}`, { verification_code: otp }).pipe(
                switchMap((response: any) => {
                    console.log(response);
                    // Store the access token in the local storage
                    // this.accessToken = response.auth_token;

                    // Set the authenticated flag to true
                    //this._authenticated = true;

                    // Store the user on the user service
                    //this._userService.user = response.user;

                    // Return a new observable with the response
                    return of(response);
                })
            );
        }
    }

    /**
     * otp resend
     */
    userOtpResend(): Observable<any> {
        const studentVerificationId = this.localStorageSevice.getDataByKey('student-Verification-id');
        if (studentVerificationId) {
            return this._httpClient.get(this.baseUrl + `student/resend-verification-code?student_id=${studentVerificationId}`).pipe(
                switchMap((response: any) => of(response))
            );
        }
    }

    /**
     * Sign in
     *
     * @param data
     */
    userSignIn(data): Observable<any> {
        return this._httpClient.post(this.baseUrl + 'student/auth/login', data, { headers: { 'Cache-Control': 'no-cache, no-store, must-revalidate' } }).pipe(
            switchMap((response: any) => {
                console.log(response);
                // Return a new observable with the response
                return of(response);
            })
        );
    }

    /**
     * send reset password link
     *
     * @param data
     */
    getResetPasswordLink(data): Observable<any> {
        return this._httpClient.get(this.baseUrl + `student/auth/forgot-password-mail?email=${data?.email}`);
    }

    /**
     * send reset password
     *
     * @param data
     */
    public resetPassword(data): Observable<any> {
        return this._httpClient.patch(this.baseUrl + `student/auth/reset-password?id=${data?.id}`, { new_password: data?.password });
    }

}
