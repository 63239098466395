/* eslint-disable @typescript-eslint/explicit-function-return-type */
/* eslint-disable @typescript-eslint/naming-convention */
import { Injectable } from '@angular/core';
import CryptoJS from 'crypto-js';
import { environment } from 'environments/environment';

// Update localstorage interface
export interface UpdateLocalStorageInterface {
    key: string;
    updateValue: any;
    updateKey?: string;
    type: string;
}
export const LOCAL_STORAGE_UPDATE_STATUS = {
    S: 'S',
    O: 'O',
};

@Injectable({
    providedIn: 'root',
})
/**
 * Local storage service
 */
export class LocalStorageService {
    /**
     * @constructor
     */
    constructor(

    ) { }

    /**
     * store in in localstorage
     * encruption enabled
     *
     * @param data
     */
    public storeUserData(data: any): void {
        if (!data) { return; }
        const userData = environment.ENCRYPT_LOCAL_STORAGE ? CryptoJS.AES.encrypt(JSON.stringify(data), environment.LOCAL_STORAGE_SECRET).toString() : JSON.stringify(data);
        localStorage.setItem('logged-in-user', userData);
    }

    /**
     * get stored used data from localstorage
     *
     * @return returns user data
     */
    public getUserData(): any {
        if (!localStorage.getItem('logged-in-user')) { return null; }
        const userData = environment.ENCRYPT_LOCAL_STORAGE ? CryptoJS.AES.decrypt(localStorage.getItem('logged-in-user'),
            environment.LOCAL_STORAGE_SECRET).toString(CryptoJS.enc.Utf8) : localStorage.getItem('logged-in-user');
        return JSON.parse(userData);
    }

    /**
     * store any data
     *
     * @param key
     * @param value
     */
    public storeData(key: string, value: any): void {
        const data = environment.ENCRYPT_LOCAL_STORAGE ? CryptoJS.AES.encrypt(JSON.stringify(value), environment.LOCAL_STORAGE_SECRET).toString() : JSON.stringify(value);
        localStorage.setItem(key, data);
    }

    /**
     * Get localstorage value by key
     *
     * @param key
     * @return
     */
    public getDataByKey(key: string): any {
        if (!localStorage.getItem(key)) { return null; }
        const data = environment.ENCRYPT_LOCAL_STORAGE ? CryptoJS.AES.decrypt(localStorage.getItem(key),
            environment.LOCAL_STORAGE_SECRET).toString(CryptoJS.enc.Utf8) : localStorage.getItem(key);
        return JSON.parse(data);
    }


    /**
     * update user data
     *
     * @param data
     * @param updateCookie
     */
    public updateUserData(data: UpdateLocalStorageInterface): void {
        let userData = '';

        // Check if data already exists in local storage
        if (localStorage.getItem('user')) {
            userData = environment.ENCRYPT_LOCAL_STORAGE ? CryptoJS.AES.decrypt(localStorage.getItem('user'),
                environment.LOCAL_STORAGE_SECRET).toString(CryptoJS.enc.Utf8) : localStorage.getItem('user');
        }
        const parsedData = userData ? JSON.parse(userData) : {};
        switch (data.type) {
            case LOCAL_STORAGE_UPDATE_STATUS.S: {
                parsedData[data.key] = data.updateValue;
            } break;
            case LOCAL_STORAGE_UPDATE_STATUS.O: {
                parsedData[data.key] = { ...parsedData[data.key], [data.updateKey]: data.updateValue };
            } break;
        }
        this.storeUserData(parsedData);
    }

    /**
     * clear all localstorage values
     */
    public clearLocalStorage(): void {
        localStorage.removeItem('user');
    }
    /**
     * clear role
     */
    // public clearRole() {
    //     localStorage.removeItem('role');
    // }
    /**
     *  remove role
     */
    public removeRole() {
        localStorage.removeItem('role');
    }
    /**
     * clear admin details
     */
    public removerUserDetails() {
        localStorage.removeItem('logged-in-user');
    }

    /**
     * remove student details
     */
    public removeStudentDetails() {
        localStorage.removeItem('student');
    }

    /**
     * remove student id
     */
    public removeStudentId() {
        localStorage.removeItem('studentId');
    }
    /**
     * remove event id
     */
    public removeEventId() {
        localStorage.removeItem('eventId');
    }
    /**
     * remove student id
     */
    public removeVerificationId() {
        localStorage.removeItem('student-Verification-id');
    }
    /**
     * remove data by key
     */
    public removeDataByKey(data) {
        localStorage.removeItem(data);
    }

    /**
     * Removes all local storage
     */
    public removeAllLocalStorage() {
        localStorage.clear();
    }
}

