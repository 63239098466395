<!-- Navigation -->
<ng-container *ngIf="isScreenSmall">
    <fuse-vertical-navigation class="dark bg-gray-900 print:hidden" [mode]="'over'" [name]="'mainNavigation'"
        [navigation]="navigation.default" [opened]="false">
        <!-- Navigation header hook -->
        <ng-container fuseVerticalNavigationContentHeader>
            <!-- Logo -->
            <div class="flex items-center h-20 pt-6 px-8">
                <img class="w-24" src="assets/logo/code-spot-white.png" alt="Logo image">
            </div>
        </ng-container>
    </fuse-vertical-navigation>
</ng-container>

<!-- Wrapper -->
<div class="">
    <!-- Header -->
    <div>
        <nav class="fixed w-full  top-0 left-0"

            [ngClass]="{'bg-white shadow-md backdrop-blur': isScrolled , 'bg-transparent': !isScrolled}" style="z-index:999 !important;">
            <div
                class="container relative max-w-7xl flex flex-wrap items-center justify-between mx-auto px-4 py-3 sm:py-4">
                <a href="https://www.codespotfoundation.org/" class="flex items-center">
                    <!-- <img src="https://flowbite.com/docs/images/logo.svg" class="h-8 mr-3" alt="Flowbite Logo"> -->
                    <img src="assets/logo/code-spot-03.png" class="w-20 sm:w-24 md:w-32">
                </a>
                <div class="flex gap-2 md:order-2 items-center">
                    <button type="button" *ngIf="studentDetail === null"
                        class="text-primary hover:text-accent focus:outline-none font-medium rounded-lg text-sm lg:text-base flex items-center h-8 p-1 sm:p-2 px-4 md:py-2 md:px-6 text-center"
                        routerLink="/user-sign-in">Login</button>
                    <button type="button" *ngIf="studentDetail === null"
                        class="text-white bg-primary hover:bg-accent border border-solid border-primary hover:border-accent focus:outline-none font-medium rounded-lg text-sm lg:text-base flex items-center h-8 sm:h-auto p-1 sm:p-2 px-4 md:py-2 md:px-6 text-center"
                        routerLink="/user-sign-up">Register</button>

                    <div *ngIf="studentDetail !== null">
                        <!-- Button -->
                        <!--first name first letter and last name first letter-->
                        <button *ngIf="!profileImage" mat-icon-button [matMenuTriggerFor]="userActions"
                            class="common-profile-web">
                            <span class="relative">
                                <span class="font-bold text-md md:text-xl">{{userDetails?.short_name}}</span>
                            </span>
                        </button>
                        <!--end-->
                        <!--img view-->
                        <button *ngIf="profileImage" mat-icon-button [matMenuTriggerFor]="userActions"
                            class="common-profile-web-img">
                            <span class="relative">
                                <img [src]="profileImage? profileImage : 'assets/images/avatars/demo-1.png'" alt=""
                                    class="common-profile-web-img object-cover mx-auto rounded-full">
                            </span>
                        </button>
                        <!--end-->
                        <mat-menu [xPosition]="'before'" #userActions="matMenu">
                            <button mat-menu-item>
                                <div class="flex items-center justify-start gap-2">
                                    <!--first name first letter and last name first letter-->
                                    <div class="common-profile-web w-12" *ngIf="!profileImage">
                                        <span
                                            class="font-bold text-md md:text-xl text-center flex items-center justify-center">{{userDetails?.short_name}}</span>

                                    </div>
                                    <!--end-->
                                    <!--img view-->
                                    <div class="common-profile-web-img w-12" *ngIf="profileImage">
                                        <img [src]="profileImage? profileImage : 'assets/images/avatars/demo-1.png'"
                                            alt="" class="common-profile-web-img object-cover mx-auto rounded-full">
                                    </div>
                                    <!--end-->
                                    <div class="">
                                        <span
                                            class="text-base md:text-lg font-medium text-turncate leading-6">{{userDetails?.first_name}}
                                            {{userDetails?.last_name}}
                                        </span><br>
                                        <span
                                            class="text-sm font-light text-turncate leading-4">{{userDetails?.email}}</span>
                                    </div>
                                </div>
                            </button>
                            <mat-divider class="my-2"></mat-divider>
                            <button mat-menu-item class="py-2" [routerLink]="['/user-profile']">
                                <img src="assets/images/profile/profile.svg" alt="profile" class="profile-icons">
                                <span>Profile</span>
                            </button>
                            <button mat-menu-item class="py-2" [routerLink]="['/user-profile/certification']">
                                <img src="assets/images/profile/certification.svg" alt="Certification"
                                    class="profile-icons">
                                <span>Certificate</span>
                            </button>
                            <button mat-menu-item class="py-2" [routerLink]="['/your-events']">
                                <img src="assets/images/profile/your-events.svg" alt="Certification"
                                    class="profile-icons">
                                <span>Your Events</span>
                            </button>
                            <button mat-menu-item class="py-2">
                                <img src="assets/images/profile/notification.svg" alt="Notification"
                                    class="profile-icons">
                                <span>Notification</span>
                            </button>
                            <button mat-menu-item class="py-2">
                                <img src="assets/images/profile/frequent-question-answers.svg" alt="FAQ"
                                    class="profile-icons">
                                <span>FAQ</span>
                            </button>
                            <button mat-menu-item class="py-2" (click)="logout()">
                                <img src="assets/images/profile/logout.svg" alt="Logout" class="profile-icons">
                                <span>Logout</span>
                            </button>
                        </mat-menu>

                        <mat-menu class="user-status-menu" #userStatus="matMenu">
                            <button mat-menu-item>
                                <span class="w-4 h-4 mr-3 rounded-full bg-green-500"></span>
                                <span>Online</span>
                            </button>
                            <button mat-menu-item>
                                <span class="w-4 h-4 mr-3 rounded-full bg-amber-500"></span>
                                <span>Away</span>
                            </button>
                            <button mat-menu-item>
                                <span class="w-4 h-4 mr-3 rounded-full bg-red-500"></span>
                                <span>Busy</span>
                            </button>
                            <button mat-menu-item>
                                <span class="w-4 h-4 mr-3 rounded-full bg-gray-400"></span>
                                <span>Invisible</span>
                            </button>
                        </mat-menu>
                    </div>

                    <button (click)="mobileNavToggle()" data-collapse-toggle="navbar-sticky" type="button"
                        class="inline-flex items-center p-2 text-sm text-gray-500 rounded-lg md:hidden hover:bg-gray-100 focus:outline-none"
                        aria-controls="navbar-sticky" aria-expanded="false">
                        <span class="sr-only">Open main menu</span>
                        <svg class="w-6 h-6" aria-hidden="true" fill="currentColor" viewBox="0 0 20 20"
                            xmlns="http://www.w3.org/2000/svg">
                            <path fill-rule="evenodd"
                                d="M3 5a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM3 10a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM3 15a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1z"
                                clip-rule="evenodd"></path>
                        </svg>
                    </button>
                </div>
                <div class="items-center justify-between w-full hidden md:flex md:w-auto md:order-1" id="navbar-sticky">
                    <ul class="flex flex-col p-4 md:p-0 mt-4 font-medium md:flex-row md:space-x-12 md:mt-0 md:border-0">
                        <li>
                            <a href="https://www.codespotfoundation.org/" target="_blank" class="block py-2 px-4 hover:bg-primary hover:text-primary hover:font-bold md:hover:bg-transparent md:text-accent font-normal hover:font-bold md:p-0 "
                                aria-current="page" >Home</a>
                        </li>
                        <li  *ngIf="studentDetail !== null">
                            <a [routerLink]="['/events']" routerLinkActive="active font-bold" [routerLinkActiveOptions]="{ exact: true }"  class="block py-2 px-4 hover:bg-primary hover:text-primary hover:font-bold md:hover:bg-transparent md:text-accent font-normal hover:font-bold md:p-0">Events</a>
                        </li>

                        <li>
                            <a href="https://www.codespotfoundation.org/#programs" target="_blank"
                                class="block py-2 px-4 hover:bg-primary hover:text-primary hover:font-bold  md:hover:bg-transparent md:text-accent font-normal hover:font-bold md:p-0">Programs</a>
                        </li>
                        <li>
                            <a href="https://www.codespotfoundation.org/#why-codespot" target="_blank"
                                class="block py-2 px-4 hover:bg-primary hover:text-primary hover:font-bold  md:hover:bg-transparent md:text-accent font-normal hover:font-bold md:p-0">How
                                We
                                Train</a>
                        </li>
                        <li>
                            <a href="https://www.codespotfoundation.org/#roadmap" target="_blank"
                                class="block py-2 px-4 hover:bg-primary hover:text-primary hover:font-bold md:hover:bg-transparent md:text-accent font-normal hover:font-bold md:p-0">Roadmap</a>
                        </li>
                        <li>
                            <a href="https://www.codespotfoundation.org/#contact" target="_blank"
                                class="block py-2 px-4 hover:bg-primary hover:text-primary hover:font-bold md:hover:bg-transparent md:text-accent font-normal hover:font-bold md:p-0">Contact
                                Us</a>
                        </li>
                       
                    </ul>
                </div>
                <div *ngIf="isMobNavToggled" class="items-center justify-between w-full md:flex md:w-auto md:order-1"
                    id="navbar-sticky">
                    <ul
                        class="flex flex-col p-4 md:p-0 mt-4 font-medium border border-gray-100 rounded-lg bg-gray-50 md:flex-row md:space-x-12 md:mt-0 md:border-0 md:bg-white dark:bg-gray-800 md:dark:bg-gray-900 dark:border-gray-700">
                        <li>
                            <a href="https://www.codespotfoundation.org/"  routerLinkActive="active" [routerLinkActiveOptions]="{ exact: true }"
                                aria-controls="navbar-sticky" aria-expanded="false"
                                class="block py-2 px-4 bg-primary text-white rounded-md md:hover:bg-transparent md:text-accent font-bold md:p-0"
                                aria-current="page">Home</a>
                        </li>
                        <li>
                            <a href="https://www.codespotfoundation.org/#programs"
                                aria-controls="navbar-sticky" aria-expanded="false"
                                class="block py-2 px-4 hover:bg-primary hover:text-white rounded-md md:hover:bg-transparent md:text-accent font-normal hover:font-bold md:p-0">Programs</a>
                        </li>
                        <li>
                            <a href="https://www.codespotfoundation.org/#why-codespot"
                                aria-controls="navbar-sticky" aria-expanded="false"
                                class="block py-2 px-4 hover:bg-primary hover:text-white rounded-md md:hover:bg-transparent md:text-accent font-normal hover:font-bold md:p-0">How
                                We
                                Train</a>
                        </li>
                        <li>
                            <a href="https://www.codespotfoundation.org/#roadmap"
                                aria-controls="navbar-sticky" aria-expanded="false"
                                class="block py-2 px-4 hover:bg-primary hover:text-white rounded-md md:hover:bg-transparent md:text-accent font-normal hover:font-bold md:p-0">Roadmap</a>
                        </li>
                        <li>
                            <a href="https://www.codespotfoundation.org/#contact"
                                aria-controls="navbar-sticky" aria-expanded="false"
                                class="block py-2 px-4 hover:bg-primary hover:text-white rounded-md md:hover:bg-transparent md:text-accent font-normal hover:font-bold md:p-0">Contact
                                Us</a>
                        </li>
                    </ul>
                </div>
            </div>
        </nav>
    </div>
    <!-- Content -->

    <router-outlet *ngIf="true"></router-outlet>

</div>
