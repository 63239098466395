/* tslint:disable:max-line-length */
import { FuseNavigationItem } from '@fuse/components/navigation';

export const defaultNavigation: FuseNavigationItem[] = [
    // {
    //     id   : 'example',
    //     title: 'Example',
    //     type : 'basic',
    //     icon : 'heroicons_outline:chart-pie',
    //     link : '/example'
    // },
    {
        id: 'home',
        title: 'Home',
        type: 'basic',
        icon: 'heroicons_outline:home',
        link: '/dashboard'
    },
    {
        id: 'event-management',
        title: 'Event Management',
        type: 'collapsable',
        icon: 'heroicons_outline:view-list',
        children: [
            {
                id: 'create-event',
                title: 'Create Event',
                type: 'basic',
                link: '/create-event'
            },
            {
                id: 'event-listing',
                title: 'Event Listing',
                type: 'basic',
                link: '/event-listing'
            },
            {
                id: 'event-status',
                title: 'Event Status',
                type: 'basic',
                link: '/event-status'
            }
        ]
    }, {
        id: 'user-management',
        title: 'User Management',
        type: 'collapsable',
        icon: 'heroicons_outline:view-list',
        children: [
            // {
            //     id: 'create-event',
            //     title: 'Create Event',
            //     type: 'basic',
            //     link: '/create-event'
            // },
            {
                id: 'user-listing',
                title: 'User Listing',
                type: 'basic',
                link: '/user-listing'
            }
        ]
    },
    {
        id: 'speaker-management',
        title: 'Speaker Management',
        type: 'collapsable',
        icon: 'heroicons_outline:view-list',
        children: [
            {
                id: 'add-speaker',
                title: 'Add Speaker',
                type: 'basic',
                link: '/add-speaker'
            },
            {
                id: 'speaker-listing',
                title: 'Speaker Listing',
                type: 'basic',
                link: '/speaker-listing'
            }
        ]
    },
];
export const compactNavigation: FuseNavigationItem[] = [
    {
        id: 'example',
        title: 'Example',
        type: 'basic',
        icon: 'heroicons_outline:chart-pie',
        link: '/example'
    }
];
export const futuristicNavigation: FuseNavigationItem[] = [
    {
        id: 'example',
        title: 'Example',
        type: 'basic',
        icon: 'heroicons_outline:chart-pie',
        link: '/example'
    }
];
export const horizontalNavigation: FuseNavigationItem[] = [
    {
        id: 'events',
        title: 'Events',
        type: 'basic',
        icon: 'heroicons_outline:home',
        link: '/events'
    }
];
