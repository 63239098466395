/* eslint-disable @typescript-eslint/member-ordering */

import { Component, OnInit, Input, ElementRef, OnDestroy } from '@angular/core';
import { ModalDataService } from 'app/modules/services/modal-data.service';
import { ModalService } from 'app/modules/services/modal.service';

@Component({
  selector: 'app-common-modal',
  templateUrl: './common-modal.component.html',
})
/**
 * Common modal for whole project
 */
export class CommonModalComponent implements OnInit, OnDestroy {
  @Input() id: string;
  @Input() heightClass: string;
  private element: any;
  @Input() widthClass: string;

  /**
   * @constructor
   *
   * @param modalService
   * @param el
   */
  constructor(
    private el: ElementRef,
    private modalService: ModalService,
    private modalDataService: ModalDataService,
  ) {
    this.element = this.el.nativeElement;
  }

  /**
   * called initially
   */
  ngOnInit(): void {
    // ensure id attribute exists
    if (!this.id) {
      console.error('modal must have an id');
      return;
    }

    // move element to bottom of page (just before </body>) so it can be displayed above everything else
    document.body.appendChild(this.element);

    // close modal on background click
    this.element.addEventListener('click', (el) => {
      if (el.target.className === 'jw-modal') {
        this.close();
      }
    });

    // add self (this modal instance) to the modal service so it's accessible from controllers
    this.modalService.add(this);
  }

  /**
   * called when component destroys
   */
  ngOnDestroy(): void {
    this.modalService.remove(this.id);
    this.element.remove();
    document.body.classList.remove('jw-modal-open');
  }

  /**
   * open model
   */
  public open(): void {
    this.element.style.display = 'block';
    this.modalDataService.sendCurrentModalStatus('MODAL_OPEN');
    document.body.classList.add('jw-modal-open');
  }

  /**
   * close model
   */
  public close(): void {
    this.element.style.display = 'none';
    this.modalDataService.sendCurrentModalStatus('MODAL_CLOSE');
    document.body.classList.remove('jw-modal-open');
  }
}
