import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'environments/environment';
import { Observable } from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class ProfileService {

    constructor(
        private http: HttpClient,
    ) { }


    /**
     * Gets user by id
     *
     * @param id
     * @returns user by id
     */
    public getUserById(id: string): Observable<any> {
        return this.http.get(`${environment.API_BASE_URL}student?id=${id}`);
    }


    /**
     * Attends event
     *
     * @param param
     * @returns event
     */
    public updateProfile(id: string, param: any): Observable<any> {
        return this.http.patch(`${environment.API_BASE_URL}student?id=${id}`, param);
    }

    /**
     * get degree
     *
     */
    public getDegree(): Observable<any> {
        return this.http.get(`${environment.API_BASE_URL}admin/degree/list`);
    }

    /**
     * Gets colleges
     *
     * @param data
     * @returns colleges
     */
    public getColleges(data: any): Observable<any> {
        return this.http.get(`${environment.API_BASE_URL}admin/college/search-random?college=${data}`);
    }

    /**
     * Gets all colleges
     *
     * @returns all colleges
     */
    public getAllColleges(): Observable<any> {
        return this.http.get(`${environment.API_BASE_URL}admin/colleges`);
    }


    /**
     * Adds qualification
     *
     * @param param
     * @returns qualification
     */
    public addQualification(param: any): Observable<any> {
        return this.http.post(`${environment.API_BASE_URL}student/educational-qualification`, param);
    }

    /**
     * Updates qualification
     *
     * @param id
     * @param param
     * @returns qualification
     */
    public updateQualification(id: string, param: any): Observable<any> {
        return this.http.patch(`${environment.API_BASE_URL}student/educational-qualification?id=${id}`, param);
    }
}
