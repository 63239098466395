/* eslint-disable @typescript-eslint/explicit-function-return-type */
/* eslint-disable @typescript-eslint/no-unused-expressions */
/* eslint-disable @typescript-eslint/member-ordering */
import { ChangeDetectorRef, Component, HostListener, OnDestroy, OnInit, ViewEncapsulation, ChangeDetectionStrategy, Input } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { FuseMediaWatcherService } from '@fuse/services/media-watcher';
import { FuseNavigationService, FuseVerticalNavigationComponent } from '@fuse/components/navigation';
import { Navigation } from 'app/core/navigation/navigation.types';
import { NavigationService } from 'app/core/navigation/navigation.service';
import { LocalStorageService } from 'app/modules/services/local-storage.service';
import { ProfileService } from 'app/modules/user/student-profile/services/profile.service';
import { ToastrService } from 'ngx-toastr';
import { DataProviderService } from 'app/modules/services/data-provider.service';
import { UserAuthService } from 'app/modules/user-auth/services/user-auth.service';
import { CommonService } from 'app/modules/services/common.service';
import { SessionStorageService } from 'app/modules/services/session-storage.service';

/**
 * conponent
 */
@Component({
    selector: 'enterprise-layout',
    templateUrl: './enterprise.component.html',
    encapsulation: ViewEncapsulation.None
})

/**
 * enterprise layout
 */
export class EnterpriseLayoutComponent implements OnInit, OnDestroy {
    public isScreenSmall: boolean;
    public navigation: Navigation;
    private _unsubscribeAll: Subject<any> = new Subject<any>();
    public studentDetail: any;
    public isMobNavToggled: boolean = false;
    public isScrolled: boolean = false;
    public userDetails: any;
    public datalistenerObservable: any;
    public profileImage: string = '';

    /**
     * Constructor
     */
    constructor(
        private _router: Router,
        private _navigationService: NavigationService,
        private _fuseMediaWatcherService: FuseMediaWatcherService,
        private _fuseNavigationService: FuseNavigationService,
        private localStorageService: LocalStorageService,
        private profileService: ProfileService,
        private toastr: ToastrService,
        private dataServiceProvider: DataProviderService,
        private userAuthService: UserAuthService,
        private commonService: CommonService,
        private sessioStorageService: SessionStorageService,
    ) {
    }

    /**
     * Getter for current year
     */
    get currentYear(): number {
        return new Date().getFullYear();
    }

    /**
     * On init
     */
    async ngOnInit() {
        this.studentDetail = this.localStorageService.getDataByKey('student') ? this.localStorageService.getDataByKey('student')
        : this.sessioStorageService.getDataByKey('student');
        if (this.studentDetail !== null) {
            await this.getUser();
        }
        this.datalistenerObservable = this.dataServiceProvider.dataListener.subscribe(async (response) => {
            if (response?.component === 'update-profile') {
                await this.getUser();
            }
        });

        // Subscribe to navigation data
        this._navigationService.navigation$
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe((navigation: Navigation) => {
                this.navigation = navigation;
            });
        // Subscribe to media changes
        this._fuseMediaWatcherService.onMediaChange$
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe(({ matchingAliases }) => {
                // Check if the screen is small
                this.isScreenSmall = !matchingAliases.includes('md');
            });
        this.commonService.profileImageUploadObs.subscribe((res) => {
            if (res) {
                this.profileImage = res?.image;
            }
        });
    }

    /**
     * On destroy
     */
    ngOnDestroy(): void {
        // Unsubscribe from all subscriptions
        this._unsubscribeAll.next();
        this._unsubscribeAll.complete();
    }

    /**
     * Toggle navigation
     *
     * @param name
     */
    toggleNavigation(name: string): void {
        // Get the navigation
        const navigation = this._fuseNavigationService.getComponent<FuseVerticalNavigationComponent>(name);
        if (navigation) {
            // Toggle the opened status
            navigation.toggle();
        }
    }

    /**
     * Mobile Navbar Toggle
     */
    public mobileNavToggle(): void {
        this.isMobNavToggled = !this.isMobNavToggled;
    }

    /**
     * host listener
     */
    @HostListener('window:scroll')
    public scrollEvent(): void {
        window.scrollY >= 10 ? (this.isScrolled = true) : (this.isScrolled = false);
    }

    /**
     * Gets user
     */
    public async getUser() {
        const userData: any = await this.getUserDetails().catch((error) => {
            this.toastr.error(error.data.message);
        });
        this.userDetails = await userData.data;
        this.profileImage = this.userDetails?.profile_picture;
        const firstLetter = this.userDetails?.first_name?.charAt(0);
        const lastLetter = this.userDetails?.last_name?.charAt(0);
        this.userDetails.short_name = firstLetter + lastLetter;
    }


    /**
     * Gets user details
     *
     * @returns
     */
    public async getUserDetails() {
        return new Promise((resolve, reject) => {
            this.profileService.getUserById(this.studentDetail?._id).subscribe({
                next: async (response: any) => {
                    resolve({ status: true, data: response.data });
                },
                error: (error) => {
                    reject({ status: false, data: error });
                },
            });
        });
    }

    /**
     * Logouts enterprise layout component
     */
    public logout() {
        this.userAuthService.logout();
        this.toastr.success('Logged out Successfully');
    }
}
